import { Fragment, useEffect, useState, useRef } from "react";
import {
	Col,
	Row,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
	CardBody,
} from "reactstrap";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../features/auth/authSlice";
import {
	useLoginMutation,
	useSuperLoginMutation,
} from "../../../Api/authApiSlice";
import toastifyAlert from "../../../components/ToastifyAlert/index";
import { toast } from "react-toastify";
import { EmpresaLogo } from "../../../config/config";
import LOGO from "../../../assets/utils/images/logo-inverse.png";
import LogoHappycel from "../../../assets/utils/images/happycel/logo-happycel_137x43.png";
import LogoWiicar from "../../../assets/utils/images/wiicar/wiicar_136x32.png";
import LogoEasy from "../../../assets/utils/images/easycell/easy_137_login.png";

const configure = {
	theme: "dark",
	position: "bottom-center",
	className: "black-background",
};

const Index = () => {
	/* redux toolkit RKT methods for making API requests */
	const [loginApiTrigger] = useLoginMutation();
	const [superLoginApiTrigger] = useSuperLoginMutation();
	// hooks
	const inputUserRef = useRef();
	const navigate = useNavigate();
	// state
	const [user, setUser] = useState("");
	const [password, setPassword] = useState("");
	// redux
	const dispatch = useDispatch();

	const { state } = useLocation();

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			const response = await toast.promise(
				loginApiTrigger({
					user: window.btoa(user),
					password: window.btoa(password),
				}).unwrap(),
				{
					pending: "Cargando...",
				},
				configure,
			);
			if (response?.status === 200) {
				setUser("");
				setPassword("");
				const userProfilee = response?.user_profile;
				const jwt = response?.token;
				const menuAccess = response?.menu_access;

				dispatch(
					setCredentials({
						user_profile: userProfilee,
						jwt,
						menu_access: menuAccess,
					}),
				);
				toastifyAlert("👌 Bienvenido...", "success");
				navigate(state?.location?.pathname ?? "/");
			}
		} catch (err) {
			console.log(err);
			if (err?.data?.message) {
				toastifyAlert(err.data?.message ?? "Error al autenticar", "warn");
			} else {
				toastifyAlert("🤯 Error: El servidor no Responde", "error");
			}
		}
	};

	const handlerUserInput = e => setUser(e.target.value);
	const handlerPasswaordInput = e => setPassword(e.target.value);

	/* Params search react router */
	const [searchParams] = useSearchParams();
	const superToken = searchParams.get("superToken") ?? null;

	useEffect(() => {
		if (superToken) {
			const getSuperLogin = async () => {
				try {
					const response = await toast.promise(
						superLoginApiTrigger({
							superToken,
						}).unwrap(),
						{
							pending: "Cargando usuario...",
						},
						configure,
					);
					if (response?.status === 200) {
						setUser("");
						setPassword("");
						const userProfilee = response?.user_profile;
						const jwt = response?.token;
						const menuAccess = response?.menu_access;

						dispatch(
							setCredentials({
								user_profile: userProfilee,
								jwt,
								menu_access: menuAccess,
							}),
						);
						toastifyAlert("👌 Bienvenido...", "success");
						navigate(state?.location?.pathname ?? "/");
					} else {
						toastifyAlert("Ingresar usuario y contraseña...", "info");
					}
				} catch (error) {
					if (error?.data?.message) {
						toastifyAlert(error.data?.message ?? "Error al autenticar", "warn");
					} else {
						toastifyAlert("🤯 Error: El servidor no Responde", "error");
					}
				}
			};
			getSuperLogin();
		} else {
			const inputUser = inputUserRef.current;
			inputUser.focus();
		}
	}, [
		dispatch,
		navigate,
		state?.location?.pathname,
		superLoginApiTrigger,
		superToken,
	]);

	return (
		<Fragment>
			<div className="h-100 bg-white">
				<div className="h-100 px-3 g-0">
					<Row className="h-100 d-flex justify-content-center align-items-center">
						<Col xs="12" md="6" lg="5" xl="4">
							<Card className="shadow-lg">
								<CardBody className="p-md-4 mx-md-4">
									<div className="d-flex align-items-center justify-content-center mt-3">
										<div className="app-logo">
											{EmpresaLogo === "happycel" ? (
												<img src={LogoHappycel} alt="logo  happycel" />
											) : EmpresaLogo === "wiicar" ? (
												<img src={LogoWiicar} alt="logo  wiicar" />
											) : EmpresaLogo === "easycell" ? (
												<img src={LogoEasy} alt="logo  easy" />
											) : (
												<img src={LOGO} alt="logo cartera365" />
											)}
										</div>
									</div>
									<div className="text-center">
										<h4 className="mt-4 mb-4 pb-1">
											Ingresa a tu cuenta con Usuario y Contraseña
										</h4>
									</div>
									<Form>
										{/* <p>Por favor, ingrese a su cuenta</p> */}
										<FormGroup>
											<Label for="user" className="visually-hidden">
												Usuario
											</Label>
											<Input
												type="text"
												name="user"
												id="user"
												placeholder="Usuario..."
												onChange={handlerUserInput}
												value={user}
												required
												ref={inputUserRef}
											/>
										</FormGroup>
										<FormGroup>
											<Label for="password" className="visually-hidden">
												Contraseña
											</Label>
											<Input
												type="password"
												name="password"
												id="password"
												placeholder="Contraseña..."
												onChange={handlerPasswaordInput}
												value={password}
												required
											/>
										</FormGroup>
										<div className="text-center pt-1 mb-3 pb-1">
											<Button color="primary" size="lg" onClick={handleSubmit}>
												Ingresar
											</Button>
										</div>
										<div className="text-center pt-1 mb-3 pb-1">
											<a className="text-muted" href="/">
												¿Se te olvidó tu contraseña?
											</a>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</Fragment>
	);
};

export default Index;
