import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	TIPE_CODIGO: null,
	TIPE_DESCRIPCION: null,
	PERFIL_OPCIONES: null,
	OPCIONES_MENU_CATALOGO: null,
	OPCION_MENU: null,
	PERFIL_VENTANAS: null,
	VENTANAS_CATALOGO: null,
};

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		addProfile: (state, action) => {
			const {
				TIPE_CODIGO,
				TIPE_DESCRIPCION,
				PERFIL_OPCIONES,
				OPCIONES_MENU_CATALOGO,
				OPCION_MENU,
				PERFIL_VENTANAS,
				VENTANAS_CATALOGO,
			} = action.payload;
			state.TIPE_CODIGO = TIPE_CODIGO;
			state.TIPE_DESCRIPCION = TIPE_DESCRIPCION;
			state.PERFIL_OPCIONES = PERFIL_OPCIONES;
			state.OPCIONES_MENU_CATALOGO = OPCIONES_MENU_CATALOGO;
			state.OPCION_MENU = OPCION_MENU;
			state.PERFIL_VENTANAS = PERFIL_VENTANAS;
			state.VENTANAS_CATALOGO = VENTANAS_CATALOGO;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addProfile } = profileSlice.actions;

export default profileSlice.reducer;
