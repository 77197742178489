import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "react-loaders";
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";
import NotFound from "../../pages/NotFound";
import { useIdleLogout } from "../../hooks/useIdleLogout";
// import TokenExpirationTime from "../../components/TokenExpirationTime/TokenExpirationTime";
// import ThemeOptions from '../ThemeOptions/index';
import { useDispatch } from "react-redux";
import { logOut } from "../../features/auth/authSlice";
import { InactivityTimeMiliseconds } from "../../config/config";
import toastifyAlert from "../../components/ToastifyAlert";

// pages
const Dashboard = lazy(() => import("../../pages/Dashboard"));
const Clients = lazy(() => import("../../pages/Clients/index"));
const ClientsView = lazy(() =>
	import("../../pages/Clients/routesForViewClient"),
);
const ClientExcepcion = lazy(() => import("../../pages/ClientExcepcion/index"));
const Portfolio = lazy(() => import("../../pages/PortfolioPages"));
const Cartera = lazy(() => import("../../pages/Cartera"));
const PreviewPdf = lazy(() => import("../../pages/PreviewPdf"));
const Payment = lazy(() => import("../../pages/Payment"));
const Precacelacion = lazy(() => import("../../pages/PaymentPrecancelacion"));
const FreeQuotes = lazy(() => import("../../pages/FreeQuotes"));
const PortfolioManagment = lazy(() => import("../../pages/PortfolioManagment"));
const CashManagment = lazy(() => import("../../pages/CashManagment"));
const CashManagmentAdministration = lazy(() =>
	import("../../pages/CashManagmentAdministration"),
);
const NumericParameters = lazy(() => import("../../pages/NumericalParameters"));
// const ExeptionClients = lazy(() => import("../../pages/ExceptionsClient"));
const EjecucionCierre = lazy(() => import("../../pages/EjecucionCierre"));
const EjecutarCierre = lazy(() => import("../../pages/EjecutarCierre"));
const EjecucionPrecierre = lazy(() => import("../../pages/EjecucionPrecierre"));

const CollectionMaintenance = lazy(() =>
	import("../../pages/CollectionMaintenance"),
);
const Discounts = lazy(() => import("../../pages/QuotesDiscount"));
const PaymentMethods = lazy(() => import("../../pages/PaymentMethods"));
const UserManagment = lazy(() => import("../../pages/UserManagment"));
const ProfileManagment = lazy(() => import("../../pages/ProfileManagment"));
const MantenimientoAgencia = lazy(() =>
	import("../../pages/MantenimientoAgencia"),
);
const MantenimientoEmpresa = lazy(() =>
	import("../../pages/MantenimientoEmpresa"),
);
const MantTasasInteres = lazy(() => import("../../pages/MantTasasInteres"));
const VentaCartera = lazy(() => import("../../pages/VentaCartera"));
const ProcesamientoVentas = lazy(() =>
	import("../../pages/ProcesamientoVentas"),
);
const Reverse = lazy(() => import("../../pages/Reverse"));
const ReversePrecancelacion = lazy(() =>
	import("../../pages/ReversePrecancelacion"),
);
const PrintAuth = lazy(() => import("../../pages/PrintAuth"));
const PrintAuthRequest = lazy(() => import("../../pages/PrintAuthRequest"));
const ProductosFinancieros = lazy(() =>
	import("../../pages/ProductosFinancieros"),
);
const CreditCancellation = lazy(() => import("../../pages/CreditCancellation"));
const CreditNormalizacion = lazy(() =>
	import("../../pages/CreditNormalization"),
);
const VentanaMenu = lazy(() => import("../../pages/VentanaMenu"));
const MantenimientoPerfil = lazy(() =>
	import("../../pages/MantenimientoPerfil"),
);
const ReportesCartera = lazy(() => import("../../pages/ReportesCartera"));
const ReportesPagos = lazy(() => import("../../pages/ReportesPagos"));

const EmpresaCobranzaAdministracion = lazy(() =>
	import("../../pages/EmpresaCobranzaAdministracion"),
);
const CargaEmpresaCobranza = lazy(() =>
	import("../../pages/CargaEmpresaCobranza"),
);
const MantRequestLogs = lazy(() => import("../../pages/RequestLog"));
const ComportamientoCrediticio = lazy(() =>
	import("../../pages/ComportamientoCrediticio"),
);
const CatalogosSeg = lazy(() => import("../../pages/CatalogosSeguridad"));
const InforVariable = lazy(() => import("../../pages/InformacionVariable"));
const DescuentoCobranza = lazy(() => import("../../pages/DescuentoCobranza"));
const ReportesDescCobranza = lazy(() =>
	import("../../pages/ReportesDescCobranza"),
);
const BotonVariable = lazy(() => import("../../pages/BotonVariable"));
const ProductosCliente = lazy(() => import("../../pages/ProductosCliente"));
const ReportereCaudacion = lazy(() => import("../../pages/ReporteRecaudacion"));
const EmailTemplate = lazy(() => import("../../pages/EmailTemplate"));
const ReportesCarteraWC = lazy(() =>
	import("../../pages/ReportesCarteraWiiCar"),
);
const EventosCreditos = lazy(() => import("../../pages/EnvioDocsMail"));
const AnuCreditGen = lazy(() => import("../../pages/AnulacionCreditosGen"));

const AppMain = () => {
	// cerrar sesion por inactividad
	const { isIdle, resetTimeout } = useIdleLogout(InactivityTimeMiliseconds);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isIdle) {
			// Aquí puedes manejar el cierre de sesión
			// Lógica para cerrar sesión, redirigir a login, etc.
			//   alert('Has sido desconectado por inactividad');
			toastifyAlert("Sesión cerrada por inactividad.", "info");
			resetTimeout();
			dispatch(logOut());
		}
	}, [dispatch, isIdle, resetTimeout]);

	return (
		<Suspense
			fallback={
				<div className="loader-container">
					<div className="loader-container-inner">
						<div className="text-center">
							<Loader type="ball-grid-cy" />
						</div>
						<h6 className="mt-3">Cargando...</h6>
					</div>
				</div>
			}
		>
			<AppHeader />
			{/* <ThemeOptions /> */}
			<div className="app-main">
				<AppSidebar />
				<div className="app-main__outer">
					<div className="app-main__inner">
						{/* <TokenExpirationTime /> */}
						<Routes>
							{/* ADMINISTRACION */}
							<Route path="/plantilla-mail/*" element={<EmailTemplate />} />
							<Route path="/usuarios/*" element={<UserManagment />} />
							<Route path="/perfiles/*" element={<ProfileManagment />} />
							<Route path="/clientes/*" element={<Clients />} />
							<Route path="/consultaclientes/*" element={<ClientsView />} />
							<Route path="/clienteexcepcion/*" element={<ClientExcepcion />} />
							<Route
								path="/mantenimientoagencia/*"
								element={<MantenimientoAgencia />}
							/>
							<Route
								path="/mantenimientoempresa/*"
								element={<MantenimientoEmpresa />}
							/>
							<Route
								path="/mantenientotasas/*"
								element={<MantTasasInteres />}
							/>
							<Route
								path="/productosfinancieros/*"
								element={<ProductosFinancieros />}
							/>
							<Route path="/ventacartera/*" element={<VentaCartera />} />
							<Route path="/ventanamenu/*" element={<VentanaMenu />} />
							<Route
								path="/mantenimientoperfil/*"
								element={<MantenimientoPerfil />}
							/>
							{/* POLITICAS */}
							<Route
								path="/parametrosnumericos/*"
								element={<NumericParameters />}
							/>
							<Route path="/catalogos/*" element={<PortfolioManagment />} />
							<Route
								path="/gastocobranza/*"
								element={<CollectionMaintenance />}
							/>
							<Route
								path="/comportcrediticio/*"
								element={<ComportamientoCrediticio />}
							/>
							<Route path="/catalogosseg/*" element={<CatalogosSeg />} />
							<Route path="/infvariable/*" element={<InforVariable />} />
							<Route path="/desccobranza/*" element={<DescuentoCobranza />} />
							<Route path="/botonvariable/*" element={<BotonVariable />} />
							{/* PAGO DE CUOTAS */}
							<Route path="/pagocuotas/*" element={<Payment />} />
							{/* CLIENTES */}
							<Route path="/prodtcliente/*" element={<ProductosCliente />} />

							{/* PROCESOS CIERRE */}
							{/* DOCUMENTOS DE CREDITO */}
							<Route path="/documentoscredito/*" element={<Portfolio />} />
							{/* FABRICA DE CREDITO */}
							{/* REPORTES */}
							<Route path="/reportespagos/*" element={<ReportesPagos />} />
							<Route
								path="/reportesdesccobranza/*"
								element={<ReportesDescCobranza />}
							/>
							{/* MENSAJERIA */}
							{/* CONSULTAS */}
							<Route path="/consultas/*" element={<Cartera />} />
							{/* PROCESOS MACIVOS */}
							<Route path="/pagospichincha/*" element={<CashManagment />} />
							<Route
								path="/configuracionpagos/*"
								element={<CashManagmentAdministration />}
							/>
							<Route path="/cierre/*" element={<EjecutarCierre />} />
							<Route path="/precierre/*" element={<EjecucionPrecierre />} />
							{/* INTERNO */}
							<Route path="/preview-pdf/*" element={<PreviewPdf />} />
							<Route
								path="/procesosespecialescartera/*"
								element={<EjecucionCierre />}
							/>
							<Route
								path="/procesoventas/*"
								element={<ProcesamientoVentas />}
							/>
							<Route
								path="/adminempresacobranza/*"
								element={<EmpresaCobranzaAdministracion />}
							/>
							<Route path="/mantrequestlogs/*" element={<MantRequestLogs />} />
							<Route path="/enviodocsmail/*" element={<EventosCreditos />} />
							<Route
								path="/cargaempresacobranza/*"
								element={<CargaEmpresaCobranza />}
							/>

							{/* POR REVISAR SI LA RUTA ESTÁ BIEN */}
							<Route path="/generarventa/*" element={<VentaCartera />} />
							<Route path="/reportescartera/*" element={<ReportesCartera />} />
							<Route
								path="/reportescarterawiicar/*"
								element={<ReportesCarteraWC />}
							/>
							<Route
								path="/reporterecaudacion/*"
								element={<ReportereCaudacion />}
							/>

							<Route path="/cuotasgratis/*" element={<FreeQuotes />} />
							<Route path="/descuentos/*" element={<Discounts />} />
							<Route path="/metodospago/*" element={<PaymentMethods />} />
							<Route path="/reversos/*" element={<Reverse />} />
							<Route path="/solicitar/*" element={<PrintAuth />} />
							<Route path="/autorizar/*" element={<PrintAuthRequest />} />
							{/* ANULACIONES */}
							<Route
								path="/anulacioncredito/*"
								element={<CreditCancellation />}
							/>
							<Route path="/anucreditogen/*" element={<AnuCreditGen />} />
							<Route
								path="/normalizacioncredito/*"
								element={<CreditNormalizacion />}
							/>
							<Route path="/precancelacion/*" element={<Precacelacion />} />
							<Route
								path="/reversosprecancelacion/*"
								element={<ReversePrecancelacion />}
							/>
							{/* Dashboard */}
							<Route path="" element={<Dashboard />} />
							{/* Not Found */}
							<Route path="*" element={<NotFound />} />
						</Routes>
					</div>
				</div>
			</div>
		</Suspense>
	);
};

export default AppMain;
