import { Fragment, useEffect } from "react";
import Loader from "react-loaders";
import { useDispatch } from "react-redux";
import { logOut } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const Index = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(logOut());
		navigate("/");
	}, [dispatch, navigate]);

	return (
		<Fragment>
			<div className="h-100 bg-white d-flex justify-content-center align-items-center">
				<h1 className="text-primary fw-bold pe-4">Cerrando sesión </h1>
				<Loader type="ball-pulse" />
			</div>
		</Fragment>
	);
};

export default Index;
