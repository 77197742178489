import { useSelector } from "react-redux";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import AppMain from "./Layout/AppMain";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./pages/UserPages/Login";
import Logout from "./pages/UserPages/Logout";
import { ToastContainer } from "react-toastify";
// import SocketConnector from "./SocketConnector";

// import UserPages from "./pages/UserPages";
import RequireAuth from "./Layout/router/RequireAuth";
import PublicRoute from "./Layout/router/PublicRoute";

const App = () => {
	const closedSmallerSidebar = false;
	const colorScheme = useSelector(state => state.themeOptions.colorScheme);
	const enableFixedHeader = useSelector(
		state => state.themeOptions.enableFixedHeader,
	);
	const enableFixedSidebar = useSelector(
		state => state.themeOptions.enableFixedSidebar,
	);
	const enableFixedFooter = useSelector(
		state => state.themeOptions.enableFixedFooter,
	);
	const enableClosedSidebar = useSelector(
		state => state.themeOptions.enableClosedSidebar,
	);
	const enableMobileMenu = useSelector(
		state => state.themeOptions.enableMobileMenu,
	);
	const enablePageTabsAlt = useSelector(
		state => state.themeOptions.enablePageTabsAlt,
	);

	return (
		<ResizeDetector
			handleWidth
			render={({ width }) => (
				<>
					<div
						className={cx(
							"app-container app-theme-" + colorScheme,
							{ "fixed-header": enableFixedHeader },
							{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
							{ "fixed-footer": enableFixedFooter },
							{ "closed-sidebar": enableClosedSidebar || width < 1250 },
							{
								"closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
							},
							{ "sidebar-mobile-open": enableMobileMenu },
							{ "body-tabs-shadow-btn": enablePageTabsAlt },
						)}
					>
						<BrowserRouter>
							<Routes>
								<Route element={<PublicRoute />}>
									<Route path="/login" element={<Login />} />
								</Route>
								<Route path="/logout" element={<Logout />} />
								<Route element={<RequireAuth />}>
									<Route path="/*" element={<AppMain />} />
								</Route>
							</Routes>
							<ToastContainer />
						</BrowserRouter>
					</div>
				</>
			)}
		/>
	);
};

export default App;
