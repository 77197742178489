import { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../features/themeOptions/themeOptions";

const Nav = () => {
	const [newNavItems, setNewNavItems] = useState([]);
	let menuItems = [];

	const enableMobileMenu = useSelector(
		state => state.themeOptions.enableMobileMenu,
	);

	const dispatch = useDispatch();
	const iconMenuItem = "pe-7s-play";
	// const iconSubMenuItem = "pe-7s-angle-right";

	menuItems = useSelector(state => state.auth.menu_access);

	const toggleMobileSidebar = () => {
		dispatch(setEnableMobileMenu(!enableMobileMenu));
	};

	useEffect(() => {
		const newNavItems = [];
		menuItems?.forEach(item => {
			let newMenuItem = {
				icon: iconMenuItem,
				label: item.opme_descripcion,
			};
			const newSubMenuItems = [];
			item.child.forEach(subItem => {
				const subMenuItem = {
					label: subItem.vent_descripcion,
					to: subItem.vent_ventana,
				};
				newSubMenuItems.push(subMenuItem);
			});

			newMenuItem = {
				...newMenuItem,
				content: newSubMenuItems,
			};
			newNavItems.push(newMenuItem);
		});
		setNewNavItems(newNavItems);
	}, []);

	return (
		<Fragment>
			<h5 className="app-sidebar__heading">Menú de Navegación</h5>
			<MetisMenu
				content={newNavItems}
				onSelected={toggleMobileSidebar}
				activeLinkFromLocation
				className="vertical-nav-menu"
				classNameLink="text-truncate"
				iconNamePrefix=""
				classNameStateIcon="pe-7s-angle-down"
			/>
		</Fragment>
	);
};

// isPathActive(path) {
//     return this.props.location.pathname.startsWith(path);
// }
export default Nav;
