import "./polyfills";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./assets/base.scss";

// Redux Persist config
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

const rootElement = document.getElementById("root");

const renderApp = App => {
	ReactDOM.render(
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>,
		rootElement,
	);
};
renderApp(App);
serviceWorker.unregister();
