import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import slice
import themeOptionsReducer from "../features/themeOptions/themeOptions";
import clientsReducer from "../features/clients/clientsSlice";
import catalogsSlice from "../features/catalogs/catalogsSlice";
import quoteSlice from "../features/quote/quoteSlice";
import gastoCobranzaSlice from "../features/gastoCobranza/gastoCobranzaSlice";
import authSlice from "../features/auth/authSlice";
import portfolioTableDescriptionSlice from "../features/portfolioTableDescription/portfolioTableDescriptionSlice";
import cashSlice from "../features/cashManagment/cashSlice";
import agenciaSlice from "../features/agencia/agenciaSlice";
import empresaSlice from "../features/empresa/empresaSlice";
import tasasIntSlice from "../features/tasasInteres/tasasIntSlice";
import ventaCarteraSlice from "../features/ventaCartera/ventaCarteraSlice";
import productosFinancierosSlice from "../features/productosFinancieros/productosFinancierosSlice";

// import metod RTK
import { apiauth } from "../Api/authApiSlice";
import { postsApi } from "../Api/helpersApi";
import freeQuotesSlice from "../features/freeQuotes/freeQuotesSlice";
import discountSlice from "../features/discount/discountSlice";
import userSlice from "../features/users/userSlice";
import profileSlice from "../features/profile/profileSlice";
import appSlice from "../features/appControllers/app";
import printAuthSlice from "../features/printAuth/printAuth";

// Redux persist config
import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
// import thunk from 'redux-thunk';

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	whitelist: ["themeOptions", "auth", "client"],
};

const rootReducer = combineReducers({
	themeOptions: themeOptionsReducer,
	app: appSlice,
	auth: authSlice,
	client: clientsReducer,
	catalogs: catalogsSlice,
	quote: quoteSlice,
	cash: cashSlice,
	freeQuote: freeQuotesSlice,
	discount: discountSlice,
	portfolioTable: portfolioTableDescriptionSlice,
	agencia: agenciaSlice,
	empresa: empresaSlice,
	tasasint: tasasIntSlice,
	ventaCartera: ventaCarteraSlice,
	gastoCobranza: gastoCobranzaSlice,
	[postsApi.reducerPath]: postsApi.reducer,
	[apiauth.reducerPath]: apiauth.reducer,
	user: userSlice,
	profile: profileSlice,
	printAuth: printAuthSlice,
	ProductosFinanc: productosFinancierosSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(postsApi.middleware, apiauth.middleware),
});

export const persistor = persistStore(store);

export default store;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.themeOptions);
