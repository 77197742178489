import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ agencia: {}, centrocosto: {}, catalogos: null }];

export const agenciaSlice = createSlice({
	name: "agencia",
	initialState,
	reducers: {
		addAgencia: (state, action) => {
			state[0].agencia = action.payload;
		},
		addCentroCosto: (state, action) => {
			state[0].centrocosto = action.payload;
		},
		addCatalogos: (state, action) => {
			state[0].catalogos = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addAgencia, addCentroCosto, addCatalogos } =
	agenciaSlice.actions;

export default agenciaSlice.reducer;
