import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ productosFinc: {}, catalogos: {} }];

export const productosFinancierosSlice = createSlice({
	name: "productosFinancieros",
	initialState,
	reducers: {
		addProductos: (state, action) => {
			state[0].productosFinc = action.payload;
		},
		addCatalogos: (state, action) => {
			state[0].catalogos = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addProductos, addCatalogos } = productosFinancierosSlice.actions;

export default productosFinancierosSlice.reducer;
