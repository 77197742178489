import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const configure = {
	theme: "colored",
	position: "bottom-center",
	autoClose: 6000,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: false,
	className: "black-background",
};

const index = (message, type) => {
	switch (type) {
		case "success":
			toast.success(message, configure);
			break;

		case "error":
			toast.error(message, configure);
			break;

		case "info":
			toast.info(message, configure);
			break;

		case "warn":
			toast.warn(message, configure);
			break;

		default:
			toast(message, configure);
			break;
	}
};

export default index;
