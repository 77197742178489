import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ cashList: [], cashUpdate: {} }];

export const cashSlice = createSlice({
	name: "cashSlice",
	initialState,
	reducers: {
		addCashUnique: (state, action) => {
			state[0].cashUpdate = action.payload;
		},
		addCash: (state, action) => {
			state[0].cashList.push(action.payload);
		},
		deleteCash: (state, action) => {
			const cashFound = state[0].cashList.find(
				cash => cash.contrapartida === action.payload.contrapartida,
			); // busca en el areglo el obejeto que tenga un id
			if (cashFound) {
				state[0].cashList.splice(state.indexOf(cashFound)); // eliminamos de task found
			}
		},
		updateCash: (state, action) => {
			const {
				codOrientacion,
				cuentaEmpresa,
				secuencial,
				comprobantePago,
				contrapartida,
				moneda,
				valor,
				formaPago,
				codigoBanco,
				tipoCuenta,
				numeroCuenta,
				tipoIdClienteBenef,
				numIdClienteBef,
				nombreClienteBef,
				direccionClienteBef,
				ciudadBenef,
				telefonoBenef,
				localidadPago,
				referencia,
				referenciaAdicional,
			} = action.payload;

			const foundCash = state[0].cashList.find(
				cash => cash.contrapartida === contrapartida,
			); // buscamos la tarea con el id
			if (foundCash) {
				foundCash.codOrientacion = codOrientacion;
				foundCash.cuentaEmpresa = cuentaEmpresa;
				foundCash.secuencial = secuencial;
				foundCash.comprobantePago = comprobantePago;
				foundCash.contrapartida = contrapartida;
				foundCash.moneda = moneda;
				foundCash.valor = valor;
				foundCash.formaPago = formaPago;
				foundCash.codigoBanco = codigoBanco;
				foundCash.tipoCuenta = tipoCuenta;
				foundCash.numeroCuenta = numeroCuenta;
				foundCash.tipoIdClienteBenef = tipoIdClienteBenef;
				foundCash.numIdClienteBef = numIdClienteBef;
				foundCash.nombreClienteBef = nombreClienteBef;
				foundCash.direccionClienteBef = direccionClienteBef;
				foundCash.ciudadBenef = ciudadBenef;
				foundCash.telefonoBenef = telefonoBenef;
				foundCash.localidadPago = localidadPago;
				foundCash.referencia = referencia;
				foundCash.referenciaAdicional = referenciaAdicional;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { addCash, deleteCash, updateCash, addCashUnique } =
	cashSlice.actions;

export default cashSlice.reducer;
