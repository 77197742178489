import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ quote: {} }];

export const quoteSlice = createSlice({
	name: "quote",
	initialState,
	reducers: {
		addQuote: (state, action) => {
			state[0].quote = action.payload;
		},
		addItems: (state, action) => {
			state[0].quote.ITEMS.push(action.payload);
		},
		updateItems: (state, action) => {
			const { TNCODI, TTARGU, TTDESC, TTDESL, TTVALO, TTVALA } = action.payload;
			const foundTask = state[0].quote.ITEMS.find(
				task => task.TTARGU === TTARGU,
			); // buscamos la tarea con el id
			if (foundTask) {
				foundTask.TNCODI = TNCODI;
				foundTask.TTARGU = TTARGU;
				foundTask.TTDESC = TTDESC;
				foundTask.TTDESL = TTDESL;
				foundTask.TTVALO = TTVALO;
				foundTask.TTVALA = TTVALA;
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { addQuote, addItems, updateItems } = quoteSlice.actions;

export default quoteSlice.reducer;
