import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ tasas: {}, datos: {}, info: {} }];

export const tasasIntSlice = createSlice({
	name: "tasasint",
	initialState,
	reducers: {
		addTasas: (state, action) => {
			state[0].tasas = action.payload;
		},
		addDatosT: (state, action) => {
			state[0].datos = action.payload;
		},
		addInfoT: (state, action) => {
			state[0].info = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addTasas, addDatosT, addInfoT } = tasasIntSlice.actions;

export default tasasIntSlice.reducer;
