import { createSlice } from "@reduxjs/toolkit";

const initialState = [
	{ cliente: {}, direccion: {}, referencia: {}, isViewInfo: false },
];

export const clientSlice = createSlice({
	name: "client",
	initialState,
	reducers: {
		addClient: (state, action) => {
			state[0].cliente = action.payload;
		},
		updateClient: (state, action) => {
			const {
				CLIE_CODIGO,
				NACI_CODIGO,
				CLIE_TIPO_PROYECTO,
				// eslint-disable-next-line camelcase
				clie_tipo,
				ACTI_CODIGO,
				CLIE_SITUACION_LABORAL,
				CLNA_NOMBRES,
				CLNA_APELLIDO1,
				CLNA_APELLIDO2,
				SEXO_CODIGO,
				CLNA_NUM_CARGAS,
				NIIN_CODIGO,
				PROF_CODIGO,
				ESCI_CODIGO,
				CLNA_FECHA_NACIMIENTO,
				CLNA_EMPRESA_TRABAJA,
			} = action.payload;
			const foundClient = state.find(
				client => client.cliente.CLIE_CODIGO === CLIE_CODIGO,
			);
			if (foundClient) {
				foundClient.cliente.NACI_CODIGO = NACI_CODIGO;
				foundClient.cliente.CLIE_TIPO_PROYECTO = CLIE_TIPO_PROYECTO;
				// eslint-disable-next-line camelcase
				foundClient.cliente.clie_tipo = clie_tipo;
				foundClient.cliente.ACTI_CODIGO = ACTI_CODIGO;
				foundClient.cliente.CLIE_SITUACION_LABORAL = CLIE_SITUACION_LABORAL;
				foundClient.cliente.CLNA_NOMBRES = CLNA_NOMBRES;
				foundClient.cliente.CLNA_APELLIDO1 = CLNA_APELLIDO1;
				foundClient.cliente.CLNA_APELLIDO2 = CLNA_APELLIDO2;
				foundClient.cliente.SEXO_CODIGO = SEXO_CODIGO;
				foundClient.cliente.CLNA_NUM_CARGAS = CLNA_NUM_CARGAS;
				foundClient.cliente.CLNA_NUM_CARGAS = CLNA_NUM_CARGAS;
				foundClient.cliente.NIIN_CODIGO = NIIN_CODIGO;
				foundClient.cliente.PROF_CODIGO = PROF_CODIGO;
				foundClient.cliente.ESCI_CODIGO = ESCI_CODIGO;
				foundClient.cliente.CLNA_FECHA_NACIMIENTO = CLNA_FECHA_NACIMIENTO;
				foundClient.cliente.CLNA_EMPRESA_TRABAJA = CLNA_EMPRESA_TRABAJA;
			}
		},
		addAdress: (state, action) => {
			state[0].direccion = action.payload;
		},
		addReference: (state, action) => {
			state[0].referencia = action.payload;
		},
		updateIsViewInfo: (state, action) => {
			state[0].isViewInfo = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addClient,
	addAdress,
	addReference,
	updateClient,
	updateIsViewInfo,
} = clientSlice.actions;

export default clientSlice.reducer;
