import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	show: true,
	credits: false,
};

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setVisibility: (state, action) => {
			const { show, credits } = action.payload;
			state.show = show;
			state.credits = credits;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setVisibility } = appSlice.actions;

export default appSlice.reducer;
