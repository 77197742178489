import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	cab_fecha_descuento: null,
	cab_descripcion: null,
	cab_status: null,
	det: [],
};

export const discountSlice = createSlice({
	name: "discount",
	initialState,
	reducers: {
		addDiscount: (state, action) => {
			// eslint-disable-next-line camelcase
			const { cab_fecha_descuento, cab_descripcion, cab_status, det } =
				action.payload;
			// eslint-disable-next-line camelcase
			state.cab_fecha_descuento = cab_fecha_descuento;
			// eslint-disable-next-line camelcase
			state.cab_descripcion = cab_descripcion;
			// eslint-disable-next-line camelcase
			state.cab_status = cab_status;
			state.det = det;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addDiscount } = discountSlice.actions;

export default discountSlice.reducer;
