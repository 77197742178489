import { useSelector } from "react-redux";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const PublicRoute = ({ children }) => {
	const token = useSelector(state => state.auth.jwt);

	const { state } = useLocation();

	if (token) {
		return <Navigate exact to={state?.location?.pathname ?? "/"} />;
	}

	return children || <Outlet />;
};

PublicRoute.propTypes = {
	children: PropTypes.node,
};

export default PublicRoute;
