import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ empresa: {}, datos: {} }];

export const empresaSlice = createSlice({
	name: "empresa",
	initialState,
	reducers: {
		addEmpresa: (state, action) => {
			state[0].empresa = action.payload;
		},
		addDatos: (state, action) => {
			state[0].datos = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addEmpresa, addDatos } = empresaSlice.actions;

export default empresaSlice.reducer;
