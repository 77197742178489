export const API = process.env.REACT_APP_TECNOFIN_URL_API;

export const apiRoutes = {
	// share routes
	login: "api-login/v1/user",
	validateToken: "api-login/v1/validate-token",
	getAllcatalogs: "/cat/view",
	getClientPaginate: "/cliente/search?page=",
	getClientDetail: "/cliente/detalle",
	cliente: "/cliente/cliente",
	updateClientPhone: "/api-dir/v1/telefono",
	updateClientAdress: "/api-dir/v1/direccion",
	clientObservations: "/api-obs/v1/observacion",
	clientExcepcion: "/api-obs/v1/excepcion-cliente",
	updateClientBond: "/api-vin/v1/vinculo",
	getAdress: "/api-dir/v1/dir-search",
	getSelectedCatalogs: "/cat/view",
	getAllPhone: "/api-dir/v1/tel-search",
	getReferences: "/api-vin/v1/vin-search",
	balances: "/api-vin/v1/balances",
	getCreditForDocuments: "/api-reportes/v1/credito-search",
	getCatalogCreditReport: "/api-reportes/v1/catalog-credit-report",
	getDocument: "/api-reportes/v1/generar-documentos",
	getReportCuotasGratis: "/api-reportes/v1/generar-documentos/cuotas-gratis",
	getReportCartola: "/api-reportes/v1/generar-documentos/cartola",
	getPortfolioTables: "/api-reportes/v1/cat-cartera",
	createPortfolio: "/api-reportes/v1/cat-guardar",
	createPortfolioItem: "/api-reportes/v1/cat-guardar-items",
	getQuoteCartola: "/api-reportes/v1/credito-search-cartola",
	postParameters: "/api-reportes/v1/parametros-search",
	getCuotasArchivo: "/api-envio-recepcion/v1/datos-envio-archivo",
	postEnvioEmail: "/api-envio-recepcion/v1/enviar-email",
	administrationCash: "/api-envio-recepcion/v1/post-administracion-cash",
	adminitrationExeption: "/cliente/exepcion",
	getModulosCierre: "/api-proceso-cierre/v1/get-modulos-tipe-codigo",
	getProcesosCierre: "/api-proceso-cierre/v1/get-procesos",
	postProcesosCierre: "/api-proceso-cierre/v1/get-procesos",
	formaspago: "/api-pagos/v1/formas-pago",
	referenciaFormaPago: "/api-pagos/v1/referencias",
	getGastoCobranza: "/api-reportes/v1/gasto-cobranza-search",
	createGastoCobranza: "/api-reportes/v1/gasto-cobranza-guardar",
	updateGastoCobranza: "/api-reportes/v1/gasto-cobranza-guardar",
	getGastoCobranzaDetalle: "/api-reportes/v1/gasto-cobranza-detalle",
	createGastoCobranzaDetalle: "/api-reportes/v1/gasto-cobranza-detalle",
	updateGastoCobranzaDetalle: "/api-reportes/v1/gasto-cobranza-detalle",
	searchCreditPayment: "/api-reportes/v1/credito-search-payment",
	searchCreditPaymentPrecancelacion:
		"/api-reportes/v1/credito-search-payment-precancelacion",
	pagos: "/api-pagos/v1/pagos-prueba",
	pagosPrecancelacion: "/api-pagos-precancelacion/v1/pagos-precancelacion",
	getFreeQuotesDetail: "/api-car/v1/cuotas-gratis",
	updateFreeQuotes: "/api-car/v1/cuotas-gratis",
	getDiscountHeaders: "/api-car/v1/descuento-cab",
	getDiscountDetails: "/api-car/v1/descuento-cuotas",
	postQuotesDiscount: "/api-car/v1/descuento-cuotas",
	postGetDetailDiscount: "/api-car/v1/descuento-det",
	getUsersInfo: "/api-login/v1/users",
	profiles: "/api-login/v1/profiles",
	profilesOptions: "/api-login/v1/profiles-options",
	searchAgencia: "/api-agencia/v1/agencia",
	createAgencia: "/api-agencia/v1/agencia-guardar",
	updateAgencia: "/api-agencia/v1/agencia-actualizar",
	searchCentros: "/api-agencia/v1/agencia-centros",
	createCentros: "/api-agencia/v1/agencia-crear-detalles",
	updateCentros: "/api-agencia/v1/agencia-actualizar-detalles",
	searchEmpresa: "/api-empresa/v1/empresa",
	updateEmpresa: "/api-empresa/v1/empresa-actualizar",
	viewEmpresaImg: "/api-empresa/v1/empresa-actualizar-img",
	getCuotasPagos: "/api-pagos/v1/cuotas-pago",
	getCuotasPagoPrecancelacion:
		"/api-pagos-precancelacion/v1/cuotas-pago-precancelacion",
	searchTasasInt: "/api-tasasinteres/v1/tasasinteres",
	searchTasasDetalles: "/api-tasasinteres/v1/tasas-detalles",
	searchTasasDetallesDatos: "/api-tasasinteres/v1/tasas-detalles-datos",
	updateTasa: "/api-tasasinteres/v1/tasas-detalles-actualizar",
	createTasaValor: "/api-tasasinteres/v1/tasas-detalles-crear",
	searchVentaCartera: "/api-ventacartera/v1/ventacartera",
	updateVentaCartera: "/api-ventacartera/v1/ventacartera-actualizar",
	createVentaCartera: "/api-ventacartera/v1/ventacartera-crear",
	postVentaCartDocs: "/api-ventacartera/v1/ventacartera-carga",
	searchDetallesVentas: "/api-ventacartera/v1/ventacartera-detalles",
	deleteDetallesVentas: "/api-ventacartera/v1/ventacartera-detalles-eliminar",
	postGenerateArchVentCart: "/api-ventacartera/v1/ventacartera",
	generateArchXls: "/api-reportescartera/v1/reportecarteraxls",
	searchVentaCarteraGen: "/api-ventacartera/v1/ventacartera-docsgen",
	getProducts: "/api-car/v1/products",
	getCreditData: "/api-car/v1/credit-data",
	getTickets: "/api-pagos/v1/tikets",
	getDetailsTickets: "/api-pagos/v1/descripcion-tikets",
	getDetailsReverse: "/api-pagos/v1/descripcion-tikets-reverso",
	getDetailsReversePrecancelacion:
		"/api-pagos-precancelacion/v1/descripcion-tikets-reverso-precancelacion",
	getSales: "/api-ventacartera/v1/ventascartera",
	getTransactions: "/api-car/v1/transaction",
	getTicketsReverse: "/api-pagos/v1/tikets-reverso",
	getTicketsReversePrecancelacion:
		"/api-pagos-precancelacion/v1/tikets-reverso-precancelacion",
	generateInfoDocs: "/api-reportes/v1/generar-info-documentos",
	postPrecierre: "/api-proceso-cierre/v1/post-precierre",
	postCierre: "/api-proceso-cierre/v1/post-cierre",
	createCreditDocument: "/api-reportes/v1/documetos-credito",
	createCreditDocumentEasycell: "/api-reportes/v1/documetos-credito-easycell",
	printAuth: "/api-car/v1/autorizacion-impresion",
	requestAuth: "/api-car/v1/solicitud-impresion",
	catalogsAuth: "/api-car/v1/catalogos-impresion",
	getProductsFinanc: "/api-productos-financieros/v1/productos-financieros",
	updtProductosFinanc: "/api-productos-financieros/v1/update_product",
	getPlazosProductosFinanc: "/api-productos-financieros/v1/productosplazos",
	updtPlazosProductosFinanc:
		"/api-productos-financieros/v1/updtproductosplazos",
	getOtrosProductosFinanc: "/api-productos-financieros/v1/productos-otrosserv",
	updtOtrosProductosFinanc:
		"/api-productos-financieros/v1/updtproductos-otrosserv",
	creditos: "/api-car/v1/credito",
	cotizacion: "/api-car/v1/cotizacion",
	subCatalogCartera: "/api-car/v1/sub-catalogo",
	getTiposPagosProdFinanc: "/api-productos-financieros/v1/prodfinantipospago",
	postTiposPagosProdFinanc: "/api-productos-financieros/v1/prodfinantipospago",
	getProdfinanfacturacion: "/api-productos-financieros/v1/prodfinanfacturacion",
	updtProdFinanFacturacion:
		"/api-productos-financieros/v1/updtprodfinanfacturacion",
	getAgenCCUsuarios: "/api-login/v1/usersdata",
	getReimpresionPagos: "/api-pagos/v1/reimpresion-pagos",
	getVentanaMenu: "/api-ventana/v1/ventanamenu",
	createVentanaMenu: "/api-ventana/v1/createventanamenu",
	updtVentanaMenu: "/api-ventana/v1/updtventanamenu",
	getOpcionesMenu: "/api-ventana/v1/opcionmenu",
	createOpcionMenu: "/api-ventana/v1/crearopcion",
	updtOpcionMenu: "/api-ventana/v1/updtopcion",
	getPerfilMenu: "/api-ventana/v1/perfilmenu",
	updtPerfilMenu: "/api-ventana/v1/updtperfilmenu",
	createPerfilMenu: "/api-ventana/v1/crearperfil",
	getPerfil: "/api-perfil/v1/perfil",
	createPerfil: "/api-perfil/v1/perfil",
	updtPerfil: "/api-perfil/v1/updtperfil",
	reversoPagos: "/api-pagos/v1/reverso-pagos",
	reversoPagosPrecancelacion:
		"/api-pagos-precancelacion/v1/reverso-pagos-precancelacion",
	getPerfilMenuOpc: "/api-ventana/v1/perfilmenuopc",
	updtPerfilMenuOpc: "/api-ventana/v1/updtperfilmenuopc",
	createPerfilMenuOpc: "/api-ventana/v1/crearperfilopc",
	deleteOpcionMenu: "/api-ventana/v1/deleteopcionmenu",
	deletePerfilVentana: "/api-ventana/v1/deleteperfilventana",
	updtDetallesVentas: "/api-ventacartera/v1/ventacartera-detactualizar",
	postPagosGenerateCuota: "/api-envio-recepcion/v1/creacion-pagos-generate",
	postValidacionArchivo: "/api-envio-recepcion/v1/validacion-pagos-generate",
	getReportesCartera: "/api-reportescartera/v1/reportescartera",
	superLogin: "/api-login/v1/super-login",
	administracionEmpresaCobranza: "/api-empresaCobranza/v1/emrpcobranzamant",
	updateadminEmpresaCobranza: "/api-empresaCobranza/v1/updtemrpcobranzamant",
	getLogRecepcion: "/api-envio-recepcion/v1/log-envio-recepcion",
	getDescargarCuotaRecepcion:
		"/api-envio-recepcion/v1/descarga-cuota-recepcion",
	getDescargarLog: "/api-envio-recepcion/v1/descarga-log",
	postEmprCobranzaXls: "/api-cargaemprcobranza/v1/cargaemrpcobranzaxls",
	cargaemrpcobranza: "/api-cargaemprcobranza/v1/cargaemrpcobranza",
	deleteCargaEmprCobranza: "/api-cargaemprcobranza/v1/deleteCargaEmprCobranza",
	getRequestLogs: "/api-log/v1/request-log",
	postDetailsRequestLogs: "/api-log/v1/request-log",
	comportCrediticio: "/api-comportamiento/v1/comportcrediticio",
	catalogcomportcrediticio: "/api-comportamiento/v1/catalogcomportcrediticio",
	comportcrediticioxls: "/api-comportamiento/v1/comportcrediticioxls",
	tiketsReportes: "/api-reportescartera/v1/reporteTikets",
	usersxls: "/api-login/v1/usersxls",
	pagosSidesoft: "/api-pagos/v1/pagos-sidesoft",
	reversosSidesoft: "/api-pagos/v1/reversos-sidesoft",
	empresaCatalogos: "/api-empresa/v1/catalogos",
	empresaSubCatalogos: "/api-empresa/v1/subCatalogo",
	moduloSistema: "/api-empresa/v1/moduloSistema",
	datosDinamicosClienteNatural: "/cliente/natural-datos-dinamicos",
	inforVariables: "/api-empresa/v1/informacionVariable",
	desctCobranza: "/api-car/v1/desctcobranza",
	desccobranzasxls: "/api-reportdesccobranza/v1/desccobranzasxls",
	botonesDinamicos: "/api-empresa/v1/botones-dinamicos",
	botonesDinamicosEmpresa: "/api-empresa/v1/botones-dinamicos-empresa",
	reportedesccobranzas: "/api-reportdesccobranza/v1/creditosdesctcobranza",
	normalizacion: "/api-car/v1/normalizacion",
	clienteProductos: "/api-car/v1/clienteproducto",
	reporteRecaudacion: "/api-reportrecaudacion/v1/reportrecaudacion",
	reporteRecaudacionXls: "/api-reportrecaudacion/v1/reportrecaudacionxls",
	getCompCrediticioClient: "/cliente/comp-crediticio",
	generateDocsEasy: "/api-reportes/v1/generar-docs-easycell",
	sendEamilCartola: "/api-reportes/v1/send-email-cartola",
	searchClientCredit: "/cliente/cliente-credito",
	emailTemplate: "/api-reportes/v1/email-template",
	generateArchWXls: "/api-reportescartera/v1/reportecarterawxls",
	getReportesCarteraWiiCar: "/api-reportescartera/v1/reportescarteraw",
	getCreditoEventos: "/api-eventocreditos/v1/creditoeventos",
	anulacionCreditoGen: "/api-car/v1/anulaciongen",
	getCreditosGenDia: "/api-car/v1/creditogendia",
};

export const getUrl = key => {
	return API + apiRoutes[key];
};
