import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	identificacion: null,
	nombre: null,
	num_con: null,
	maxgra: null,
	datgra: null,
	czmogr: null,
	cznugr: null,
};

export const freeQuotesSlice = createSlice({
	name: "freeQuote",
	initialState,
	reducers: {
		addFreeQuote: (state, action) => {
			// eslint-disable-next-line camelcase
			const {
				identificacion,
				nombre,
				// eslint-disable-next-line camelcase
				num_con,
				maxgra,
				datgra,
				czmogr,
				cznugr,
			} = action.payload;
			state.identificacion = identificacion;
			state.nombre = nombre;
			// eslint-disable-next-line camelcase
			state.num_con = num_con;
			state.maxgra = maxgra;
			state.datgra = datgra;
			state.czmogr = czmogr;
			state.cznugr = cznugr;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addFreeQuote } = freeQuotesSlice.actions;

export default freeQuotesSlice.reducer;
