import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ portfolioTable: {}}];

export const portfolioTable = createSlice({
  name: "portfolioTable",
  initialState,
  reducers: {
    addportfolioTable: (state, action) => {
      state[0].portfolioTable = action.payload;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const { addportfolioTable } = portfolioTable.actions;

export default portfolioTable.reducer;
