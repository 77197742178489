import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Slider } from "react-burgers";
import AppMobileMenu from "../AppMobileMenu";
import { setEnableClosedSidebar } from "../../features/themeOptions/themeOptions";
import LOGO from "../../assets/utils/images/logo-inverse.png";
import LogoHappycel from "../../assets/utils/images/happycel/logo_97x23.png";
import LogoWiicar from "../../assets/utils/images/wiicar/wiicar_97x23.png";
import LogoEasy from "../../assets/utils/images/easycell/logo_easycellname97x23.png";
import { EmpresaLogo } from "../../config/config";

const HeaderLogo = () => {
	const [active, setActive] = useState(false);
	const enableClosedSidebar = useSelector(
		state => state.themeOptions.enableClosedSidebar,
	);
	// const enableMobileMenu = useSelector((state) => state.themeOptions.enableMobileMenu)
	// const enableMobileMenuSmall = useSelector((state) => state.themeOptions.enableMobileMenuSmall)

	const dispatch = useDispatch();

	const toggleEnableClosedSidebar = () => {
		dispatch(setEnableClosedSidebar(!enableClosedSidebar));
	};

	return (
		<Fragment>
			<div className="app-header__logo">
				<div className="logo-src">
					{EmpresaLogo === "happycel" ? (
						<img src={LogoHappycel} alt="Logo Happycel" />
					) : EmpresaLogo === "wiicar" ? (
						<img src={LogoWiicar} alt="Logo Wiicar" />
					) : EmpresaLogo === "easycell" ? (
						<img src={LogoEasy} alt="Logo Easy" />
					) : (
						<img src={LOGO} alt="Logo Cartera365" />
					)}
				</div>
				<div className="header__pane ms-auto">
					<div onClick={toggleEnableClosedSidebar}>
						<Slider
							width={26}
							lineHeight={2}
							lineSpacing={5}
							color="#6c757d"
							active={active}
							onClick={() => setActive(!active)}
						/>
					</div>
				</div>
			</div>
			<AppMobileMenu />
		</Fragment>
	);
};

export default HeaderLogo;
