import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	id: null,
	agencia: null,
	numOper: null,
	usuario: null,
	fecha: null,
	mots: null,
	mota: null,
	auth: null,
	obss: null,
	obsa: null,
	motsd: null,
	fechaAct: null,
	cedl: null,
};

export const printAuthSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		addData: (state, action) => {
			const {
				id,
				agencia,
				numOper,
				usuario,
				fecha,
				mots,
				mota,
				auth,
				obss,
				obsa,
				motsd,
				fechaAct,
				cedl,
			} = action.payload;
			state.id = id;
			state.agencia = agencia;
			state.numOper = numOper;
			state.usuario = usuario;
			state.fecha = fecha;
			state.mots = mots;
			state.mota = mota;
			state.auth = auth;
			state.obss = obss;
			state.obsa = obsa;
			state.motsd = motsd;
			state.fechaAct = fechaAct;
			state.cedl = cedl;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addData } = printAuthSlice.actions;

export default printAuthSlice.reducer;
