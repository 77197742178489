import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	USUA_CODIGO: null,
	USUA_FECHA_CREACION: null,
	ESUS_CODIGO: null,
	ESUS_DESC: null,
	USUA_LOGIN: null,
	USUA_CLAVE: null,
	USUA_IDENTIFICACION: null,
	USUA_NOMBRE: null,
	MAIL_USUARIO: null,
	TELEFONO_USUARIO: null,
	USUA_ADMINISTRADOR: null,
	USUA_FECHA_INICIO: null,
	USUA_PERIOCIDAD: null,
	USUA_FECHA_FIN: null,
	TIPE_CODIGO: null,
	ASES_NOMBRE: null,
	AGENCIA: {},
	AGENCIAS: {},
	CENTROS: {},
	PERFILES: {},
};

export const userEditSlice = createSlice({
	name: "userEdit",
	initialState,
	reducers: {
		addUser: (state, action) => {
			const {
				USUA_CODIGO,
				USUA_FECHA_CREACION,
				ESUS_CODIGO,
				ESUS_DESC,
				USUA_LOGIN,
				USUA_CLAVE,
				USUA_IDENTIFICACION,
				USUA_NOMBRE,
				MAIL_USUARIO,
				TELEFONO_USUARIO,
				USUA_ADMINISTRADOR,
				USUA_FECHA_INICIO,
				USUA_PERIOCIDAD,
				USUA_FECHA_FIN,
				TIPE_DESCRIPCION,
				TIPE_CODIGO,
				ASES_CODIGO,
				ASES_NOMBRE,
				AGENCIA,
				AGENCIAS,
				CENTROS,
				PERFILES,
			} = action.payload;
			state.USUA_CODIGO = USUA_CODIGO;
			state.USUA_FECHA_CREACION = USUA_FECHA_CREACION;
			state.ESUS_CODIGO = ESUS_CODIGO;
			state.ESUS_DESC = ESUS_DESC;
			state.USUA_LOGIN = USUA_LOGIN;
			state.USUA_CLAVE = USUA_CLAVE;
			state.USUA_IDENTIFICACION = USUA_IDENTIFICACION;
			state.USUA_NOMBRE = USUA_NOMBRE;
			state.MAIL_USUARIO = MAIL_USUARIO;
			state.TELEFONO_USUARIO = TELEFONO_USUARIO;
			state.USUA_ADMINISTRADOR = USUA_ADMINISTRADOR;
			state.USUA_FECHA_INICIO = USUA_FECHA_INICIO;
			state.USUA_PERIOCIDAD = USUA_PERIOCIDAD;
			state.USUA_FECHA_FIN = USUA_FECHA_FIN;
			state.TIPE_CODIGO = TIPE_CODIGO;
			state.TIPE_DESCRIPCION = TIPE_DESCRIPCION;
			state.ASES_NOMBRE = ASES_NOMBRE;
			state.ASES_CODIGO = ASES_CODIGO;
			state.AGENCIA = AGENCIA;
			state.AGENCIAS = AGENCIAS;
			state.CENTROS = CENTROS;
			state.PERFILES = PERFILES;
		},
	},
});

// Action creators are generated for each case reducer function
export const { addUser } = userEditSlice.actions;

export default userEditSlice.reducer;
