import moment from "moment";
import jsPDF from "jspdf";
import { jwtDecode } from "jwt-decode";

/**
 * It takes a date string, parses it into a moment object, then formats it into a string.
 * format the date type 1983-11-23T00:00:00Z to YYYYY-MM-DD
 * @param date - the date you want to format
 * @param format - the date format (default: "YYYYY-MM-D")
 * @returns A function that takes a date and returns a formatted date.
 */
export const formatDateZone = (date, format = "YYYY-MM-DD") => {
	if (!date) return null;
	const dateParse = moment.parseZone(date);
	return dateParse.format(format);
};

/**
 * It takes a client object and a values object and returns a new object with the client object's
 * properties and the values object's properties
 * @param client - {
 * @param values - {
 * @returns An object with two properties, cliente and detalle.
 */
export const formattingDataUpdateNaturalClient = (client, values) => {
	const {
		NACI_CODIGO,
		CLIE_TIPO_PROYECTO,
		// eslint-disable-next-line camelcase
		clie_tipo,
		ACTI_CODIGO,
		CLIE_SITUACION_LABORAL,
		CLNA_NOMBRES,
		CLNA_APELLIDO1,
		CLNA_APELLIDO2,
		SEXO_CODIGO,
		CLNA_NUM_CARGAS,
		NIIN_CODIGO,
		PROF_CODIGO,
		ESCI_CODIGO,
		CLNA_FECHA_NACIMIENTO,
		CLNA_EMPRESA_TRABAJA,
	} = values;

	const {
		CLIE_CODIGO,
		TICL_CODIGO,
		CLIE_IDENTIFICACION,
		TIDO_CODIGO,
		ASES_CODIGO,
		// eslint-disable-next-line camelcase
		clie_estado,
	} = client;

	const data = {
		cliente: {
			CLIE_CODIGO,
			TICL_CODIGO,
			CLIE_IDENTIFICACION,
			NACI_CODIGO,
			TIDO_CODIGO,
			ACTI_CODIGO,
			ASES_CODIGO,
			// eslint-disable-next-line camelcase
			clie_estado,
			// eslint-disable-next-line camelcase
			clie_tipo,
			CLIE_TIPO_PROYECTO,
		},
		detalle: {
			PROF_CODIGO,
			NIIN_CODIGO,
			SEXO_CODIGO,
			ESCI_CODIGO,
			CLNA_NOMBRES,
			CLNA_APELLIDO1,
			CLNA_APELLIDO2,
			CLNA_FECHA_NACIMIENTO,
			CLIE_SITUACION_LABORAL,
			CLNA_NUM_CARGAS: CLNA_NUM_CARGAS === "" ? null : CLNA_NUM_CARGAS,
			CLNA_EMPRESA_TRABAJA,
		},
	};
	return data;
};

/**
 * It takes a template and data, replaces the data in the template, and then generates a PDF from the
 * template
 * @param template - The HTML template that you want to convert to PDF.
 * @param data - This is the data that you want to replace in the template.
 * @param [withDocument=21cm] - The width of the document.
 * @param [margin=10] - The margin of the document.
 * @returns A promise.
 */
export const generatePDF = (
	template,
	data,
	withDocument = "21cm",
	margin = 10,
	descargar = false,
) => {
	// check if they send the template and data
	if (!data || !template) return null;
	// replace data in the template
	const variablesDoc = Object.keys(data);
	for (let i = 0; i < variablesDoc.length; i++) {
		const ExpReg = new RegExp(`{{${variablesDoc[i]}}}`, "gim");
		template = template.replace(ExpReg, data[variablesDoc[i]]);
	}
	const envol = `<div style="background-color: white; color: black; width: ${withDocument}">${template}</div>`;
	// generate pdf
	// eslint-disable-next-line new-cap
	const doc = new jsPDF("p", "pt", "a4");
	const scale = (doc.internal.pageSize.width - margin * 2) / 794;
	return new Promise(function (resolve, reject) {
		doc.html(envol, {
			x: margin,
			y: margin,
			html2canvas: {
				scale,
			},
			callback: function (doc) {
				// return object pdf
				if (descargar) {
					resolve(doc.output("datauri"));
				} else {
					resolve(doc);
				}

				// doc.output("dataurlnewwindow", { filename: "sample.pdf" });
			},
		});
	});
};

/**
 * It takes a string of numbers and returns a formatted phone number.
 * @param value - The value of the input field.
 * @returns the formatted phone number.
 */
export const formatPhoneNumber = value => {
	if (!value) return value;
	const phoneNumber = value.replace(/[^\d]/g, "");
	const phoneNumberLength = phoneNumber.length;
	if (phoneNumberLength < 4) return phoneNumber;
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6,
	)}-${phoneNumber.slice(6, 10)}`;
};

/**
 * It takes a number and returns a string with the number formatted as a currency.
 * @param currency - The currency you want to format.
 * @param [locate=de-DE] - The locale to use. The default is "de-DE".
 * @returns A function that takes two arguments, currency and locate.
 */
export const currencyFormatter = (currency, locate = "de-DE") => {
	const formattedCurrencyDecimal = Number.parseFloat(currency);
	const formattedCurrencyLocal = formattedCurrencyDecimal.toLocaleString(
		locate,
		{
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		},
	);
	return formattedCurrencyLocal;
};

/**
 * The function converts a blob object to a base64 encoded string using a FileReader object.
 * @param blob - The `blob` parameter is an instance of the `Blob` class in JavaScript, which
 * represents a file-like object of immutable, raw data. It can be an image, audio, video, or any other
 * type of file. The `blobToBase64` function takes this `blob` object
 * @returns A Promise object is being returned.
 */
export const blobToBase64 = blob => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise(resolve => {
		reader.onloadend = () => {
			resolve(reader.result);
		};
	});
};

/**
 * The function `isExpired` checks if a given token has expired by comparing its expiration time with
 * the current time.
 * @returns a boolean value. It returns true if the current time is greater than the expiration time of
 * the token, indicating that the token has expired. Otherwise, it returns false.
 */
export const isExpired = token => {
	// Decoding the token
	const decoded = jwtDecode(token);

	const decodedExp = decoded.exp || null;

	if (decodedExp === null) {
		return true;
	}

	// Get the expiration date. The expiration date is in UTC, so it is multiplied by 1000s.
	const expirationTime = new Date(decodedExp * 1000).getTime();

	// Get current local date
	const currentTime = new Date().getTime();

	return currentTime > expirationTime;
};

/**
 * The function `remainingExpirationTime` calculates the remaining time until a token expires based on
 * the token's expiration date.
 * @returns the remaining expiration time in milliseconds.
 */
export const remainingExpirationTime = token => {
	// Decoding the token
	const decoded = jwtDecode(token);

	const decodedExp = decoded.exp || null;

	if (decodedExp === null) {
		return 0;
	}

	// Get the expiration date. The expiration date is in UTC, so it is multiplied by 1000s.
	const expirationTime = new Date(decodedExp * 1000).getTime();

	// Get current local date
	const currentTime = new Date().getTime();

	const remainingTime = expirationTime - currentTime;

	// console.log(5 * 60 * 1000);

	// Muestra un mensaje de advertencia si quedan menos de 5 minutos
	// if (remainingTime <= 5 * 60 * 1000 && remainingTime >= 0) {
	// 	toastifyAlert(
	// 		"Tu sesión está a punto de terminar. Por favor, guarda tus cambios.",
	// 		"info",
	// 	);
	// }

	return remainingTime;
};

/**
 * The function `checkMatchMenu` checks if a given path matches any URL in a menu array, including
 * nested children.
 * @param menu - The `menu` parameter is an array of objects representing menu items. Each menu item
 * object contains a `vent_ventana` property which is a URL associated with that menu item. The menu
 * items may also have a `child` property which is an array of nested menu items.
 * @param path - The `path` parameter in the `checkMatchMenu` function represents the current path or
 * URL that you want to check against the menu items to see if there is a match. The function iterates
 * through the menu items and compares the `vent_ventana` property of each menu item with the
 * @returns The function `checkMatchMenu` returns a boolean value - `true` if the `path` matches any
 * URL in the `menu` array or its children, and `false` if no match is found.
 */
export const checkMatchMenu = (menu, path, whiteList) => {
	// Check if the path is not a valid string
	if (typeof path !== "string") {
		return false;
	}

	// Check if the path is on the whitelist
	if (whiteList && whiteList.some(url => path.startsWith(url))) {
		return true;
	}

	// Check if the menu is valid
	if (!Array.isArray(menu) || menu.length === 0) {
		return false;
	}

	// We go through the menu
	for (let i = 0; i < menu.length; i++) {
		const menuItem = menu[i];

		// If the local path starts with the url of the menu, we return true
		if (path.startsWith(menuItem.vent_ventana)) {
			return true;
		}

		// If the menu item has children, we recursively go through them
		if (menuItem.child && menuItem.child.length > 0) {
			const childResult = checkMatchMenu(menuItem.child, path);
			if (childResult) {
				return true;
			}
		}
	}

	// Si no se encontró ninguna coincidencia, retornamos false
	return false;
};
