import { getUrl } from "../config/apiRoutes";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

export const postsApi = createApi({
	reducerPath: "postsApi",
	baseQuery: retry(
		fetchBaseQuery({
			baseUrl: "",
			prepareHeaders: (headers, { getState }) => {
				// By default, if we have a token in the store, let's use that for authenticated requests
				const token = getState().auth.jwt;

				headers.set("Content-Type", "application/json");
				if (token) {
					headers.set("Authorization", `Bearer ${token}`);
				}
				return headers;
			},
		}),
		{
			maxRetries: 0,
		},
	),
	endpoints: builder => ({
		getAllCatologs: builder.query({
			query: () => getUrl("getAllcatalogs"),
		}),
		searchClient: builder.mutation({
			query: ({ page, data }) => ({
				url: getUrl("getClientPaginate") + page,
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		searchClientDetail: builder.mutation({
			query: data => ({
				url: getUrl("getClientDetail"),
				method: "post",
				body: data,
			}),
		}),
		updateClient: builder.mutation({
			query: data => ({
				url: getUrl("cliente"),
				method: "put",
				body: data,
			}),
		}),
		getCliente: builder.query({
			query: params => getUrl("cliente") + params || "",
		}),
		updatePhone: builder.mutation({
			query: data => ({
				url: getUrl("updateClientPhone"),
				method: "put",
				body: data,
			}),
		}),
		updateAddress: builder.mutation({
			query: data => ({
				url: getUrl("updateClientAdress"),
				method: "put",
				body: data,
			}),
		}),
		updateObservations: builder.mutation({
			query: data => ({
				url: getUrl("clientObservations"),
				method: "put",
				body: data,
			}),
		}),
		updateBond: builder.mutation({
			query: data => ({
				url: getUrl("updateClientBond"),
				method: "put",
				body: data,
			}),
		}),
		getAdressClient: builder.mutation({
			query: data => ({
				url: getUrl("getAdress"),
				method: "post",
				body: data,
			}),
		}),
		getSelectedCatalogs: builder.mutation({
			query: data => ({
				url: getUrl("getSelectedCatalogs"),
				method: "post",
				body: data,
			}),
		}),
		getFreeQuotesDetail: builder.query({
			query: data => getUrl("getFreeQuotesDetail") + data,
		}),
		postFreeQuotes: builder.mutation({
			query: data => ({
				url: getUrl("updateFreeQuotes"),
				method: "POST",
				body: data,
			}),
		}),
		getAllPhone: builder.mutation({
			query: data => ({
				url: getUrl("getAllPhone"),
				method: "post",
				body: data,
			}),
		}),
		getReferences: builder.mutation({
			query: data => ({
				url: getUrl("getReferences"),
				method: "post",
				body: data,
			}),
		}),
		getBalances: builder.query({
			query: queryParams => getUrl("balances") + queryParams,
		}),
		getCreeditForDocs: builder.query({
			query: queryParams => getUrl("getCreditForDocuments") + queryParams,
		}),
		getCatalogCreditReport: builder.query({
			query: ({ catalogCode, numCotizacion, statusDoc }) =>
				getUrl("getCatalogCreditReport") +
				`/${catalogCode}?cznuz=${numCotizacion}&status_doc=${statusDoc}`,
		}),
		generateDocument: builder.mutation({
			query: ({ data, docGenerate }) => ({
				url: getUrl("getDocument") + "/" + docGenerate,
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		getReportCuotasGratis: builder.query({
			query: ({ agenCodigo, numCotizacion }) =>
				getUrl("getReportCuotasGratis") +
				`/${numCotizacion}?agen_codigo=${agenCodigo}`,
		}),
		getReportCartola: builder.query({
			query: ({ numCredit, cznucz }) =>
				getUrl("getReportCartola") +
				`?&num_credit=${numCredit}&cznucz=${cznucz}`,
		}),
		getPortfolioTables: builder.mutation({
			query: ({ data, page }) => ({
				url: getUrl("getPortfolioTables") + "?page=" + page,
				method: "POST",
				body: data,
			}),
		}),
		postPortfolioTables: builder.mutation({
			query: data => ({
				url: getUrl("createPortfolio"),
				method: "POST",
				body: data,
			}),
		}),
		updatePortfolioTables: builder.mutation({
			query: ({ data }) => ({
				url: getUrl("createPortfolio"),
				method: "put",
				body: data,
			}),
		}),
		postPortfolioItems: builder.mutation({
			query: data => ({
				url: getUrl("createPortfolioItem"),
				method: "POST",
				body: data,
			}),
		}),
		getQuoteCartola: builder.query({
			query: ({ page, identificacion, numCredito, can, nfq, ind }) =>
				getUrl("getQuoteCartola") +
				`?page=${page}&identificacion=${identificacion}&num_credito=${
					numCredito || ""
				}${can || ""}${nfq || ""}${ind || ""}`,
		}),
		postParameters: builder.mutation({
			query: ({ data, pagina }) => ({
				url: getUrl("postParameters") + "?page=" + pagina,
				method: "post",
				body: data,
			}),
		}),
		getCuotasArchivo: builder.mutation({
			query: () => ({
				url: getUrl("getCuotasArchivo"),
				method: "get",
			}),
		}),
		postEnviarEmail: builder.mutation({
			query: data => ({
				url: getUrl("postEnvioEmail"),
				method: "post",
				body: data,
			}),
		}),
		postCash: builder.mutation({
			query: data => ({
				url: getUrl("administrationCash"),
				method: "post",
				body: data,
			}),
		}),
		putCash: builder.mutation({
			query: data => ({
				url: getUrl("administrationCash"),
				method: "put",
				body: data,
			}),
		}),
		getCash: builder.query({
			query: ({ page }) => getUrl("administrationCash") + "?page=" + page,
		}),
		getExeption: builder.mutation({
			query: codigoCliente => ({
				url: getUrl("adminitrationExeption") + "?codCliente=" + codigoCliente,
				method: "get",
			}),
		}),
		postExeption: builder.mutation({
			query: data => ({
				url: getUrl("adminitrationExeption"),
				method: "post",
				body: data,
			}),
		}),
		putExeption: builder.mutation({
			query: data => ({
				url: getUrl("adminitrationExeption"),
				method: "put",
				body: data,
			}),
		}),
		// ! El metodo esta mal creado, si es de tipo GET se debe de utilizar builder.query()
		getDireccionIp: builder.mutation({
			query: () => ({
				url: "https://ipapi.co/json/",
				method: "GET",
			}),
		}),
		getModulosCierre: builder.mutation({
			query: tipeCodigo => ({
				url: getUrl("getModulosCierre") + "?tipeCodigo=" + tipeCodigo,
				method: "get",
			}),
		}),
		getProcesosCierre: builder.mutation({
			query: mosiCodigo => ({
				url: getUrl("getProcesosCierre") + "?mosiCodigo=" + mosiCodigo,
				method: "get",
			}),
		}),
		postProcesosCierre: builder.mutation({
			query: data => ({
				url: getUrl("postProcesosCierre"),
				method: "post",
				body: data,
			}),
		}),
		putProcesosCierre: builder.mutation({
			query: data => ({
				url: getUrl("putProcesosCierre"),
				method: "put",
				body: data,
			}),
		}),
		getGastoCobranza: builder.mutation({
			query: ({ data, page }) => ({
				url: getUrl("getGastoCobranza") + "?page=" + page,
				method: "POST",
				body: data,
			}),
		}),
		createGastoCobranza: builder.mutation({
			query: data => ({
				url: getUrl("createGastoCobranza"),
				method: "post",
				body: data,
			}),
		}),
		updateGastoCobranza: builder.mutation({
			query: data => ({
				url: getUrl("updateGastoCobranza"),
				method: "post",
				body: data,
			}),
		}),
		getGastoCobranzaDetalle: builder.query({
			query: code => `${getUrl("getGastoCobranzaDetalle")}/${code}`,
		}),
		createGastoCobranzaDetalle: builder.mutation({
			query: data => ({
				url: getUrl("createGastoCobranzaDetalle"),
				method: "post",
				body: data,
			}),
		}),
		updateGastoCobranzaDetalle: builder.mutation({
			query: data => ({
				url: getUrl("updateGastoCobranzaDetalle"),
				method: "put",
				body: data,
			}),
		}),
		searchCreditPayment: builder.query({
			query: ({ page, identificacion, numCredito, usuario = "" }) =>
				getUrl("searchCreditPayment") +
				`?page=${page}&identificacion=${identificacion}&num_credito=${numCredito}&usuario=${usuario}`,
		}),
		searchCreditPaymentPrecancelacion: builder.query({
			query: ({ page, identificacion, numCredito, usuario = "" }) =>
				getUrl("searchCreditPaymentPrecancelacion") +
				`?page=${page}&identificacion=${identificacion}&num_credito=${numCredito}&usuario=${usuario}`,
		}),
		postPagos: builder.mutation({
			query: data => ({
				url: getUrl("pagos"),
				method: "post",
				body: data,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}),
		}),
		postPagosPrecancelacion: builder.mutation({
			query: data => ({
				url: getUrl("pagosPrecancelacion"),
				method: "post",
				body: data,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}),
		}),
		searchAgencia: builder.query({
			query: ({ page, agencia }) =>
				getUrl("searchAgencia") + `?page=${page}&agencia=${agencia}`,
		}),
		createAgencia: builder.mutation({
			query: data => ({
				url: getUrl("createAgencia"),
				method: "post",
				body: data,
			}),
		}),
		getDiscountHeaders: builder.query({
			query: ({ desc, date, page }) =>
				getUrl("getDiscountHeaders") +
				`?desc=${desc}&date=${date}&page=${page}`,
		}),
		getDiscountDetails: builder.query({
			query: data => getUrl("getDiscountDetails") + data,
		}),
		postQuotesDiscount: builder.mutation({
			query: data => ({
				url: getUrl("getDiscountDetails"),
				method: "post",
				body: data,
			}),
		}),
		postGetDetailDiscount: builder.mutation({
			query: data => ({
				url: getUrl("postGetDetailDiscount"),
				method: "post",
				body: data,
			}),
		}),
		deleteDetailDiscount: builder.mutation({
			query: data => ({
				url: getUrl("postGetDetailDiscount"),
				method: "delete",
				body: data,
			}),
		}),
		getPaymentMethods: builder.query({
			query: () => getUrl("formaspago"),
		}),
		postPaymentMethods: builder.mutation({
			query: data => ({
				url: getUrl("formaspago"),
				method: "post",
				body: data,
			}),
		}),
		getReferencesPaymentMethods: builder.query({
			query: FGCDFG => getUrl("referenciaFormaPago") + "?FGCDFG=" + FGCDFG,
		}),
		postReferencesPaymentMethods: builder.mutation({
			query: data => ({
				url: getUrl("referenciaFormaPago"),
				method: "post",
				body: data,
			}),
		}),
		putReferencesPaymentMethods: builder.mutation({
			query: data => ({
				url: getUrl("referenciaFormaPago"),
				method: "put",
				body: data,
			}),
		}),
		updateAgencia: builder.mutation({
			query: data => ({
				url: getUrl("updateAgencia"),
				method: "put",
				body: data,
			}),
		}),
		searchCentros: builder.query({
			query: ({ page, codigoAgencia }) =>
				getUrl("searchCentros") +
				`?page=${codigoAgencia}&codigo_agencia=${codigoAgencia}`,
		}),
		createCentros: builder.mutation({
			query: data => ({
				url: getUrl("createCentros"),
				method: "post",
				body: data,
			}),
		}),
		updateCentros: builder.mutation({
			query: data => ({
				url: getUrl("updateCentros"),
				method: "put",
				body: data,
			}),
		}),
		searchEmpresa: builder.query({
			query: ({ page }) => getUrl("searchEmpresa") + `?page=${page}`,
		}),
		updateEmpresa: builder.mutation({
			query: data => ({
				url: getUrl("updateEmpresa"),
				method: "put",
				body: data,
			}),
		}),
		getCuotasPago: builder.query({
			query: ({ ctnuco, ctanex, pdcdsp }) =>
				getUrl("getCuotasPagos") +
				`?&CTNUCO=${ctnuco}&CTANEX=${ctanex}&PDCDSP=${pdcdsp}`,
		}),
		getCuotasPagoPrecancelacion: builder.query({
			query: ({ ctnuco, ctanex, pdcdsp }) =>
				getUrl("getCuotasPagoPrecancelacion") +
				`?&CTNUCO=${ctnuco}&CTANEX=${ctanex}&PDCDSP=${pdcdsp}`,
		}),
		searchTasasInt: builder.query({
			query: ({ page }) => getUrl("searchTasasInt") + `?page=${page}`,
		}),
		searchTasasDetalles: builder.query({
			query: ({ page, codigoTabla }) =>
				getUrl("searchTasasDetalles") +
				`?page=${page}&codigo_tabla=${codigoTabla}`,
		}),
		searchTasasDetallesDatos: builder.query({
			query: ({ page, codigoTabla, codigoInf }) =>
				getUrl("searchTasasDetallesDatos") +
				`?page=${page}&codigo_tabla=${codigoTabla}&codigo_inf=${codigoInf}`,
		}),
		updateTasa: builder.mutation({
			query: data => ({
				url: getUrl("updateTasa"),
				method: "put",
				body: data,
			}),
		}),
		createTasaValor: builder.mutation({
			query: data => ({
				url: getUrl("createTasaValor"),
				method: "post",
				body: data,
			}),
		}),
		searchVentaCartera: builder.query({
			query: ({ page, fecha }) =>
				getUrl("searchVentaCartera") + `?page=${page}&fecha=${fecha || ""}`,
		}),
		updateVentaCartera: builder.mutation({
			query: data => ({
				url: getUrl("updateVentaCartera"),
				method: "put",
				body: data,
			}),
		}),
		createVentaCartera: builder.mutation({
			query: data => ({
				url: getUrl("createVentaCartera"),
				method: "post",
				body: data,
			}),
		}),
		postVentaCartDocs: builder.mutation({
			query: data => ({
				url: getUrl("postVentaCartDocs"),
				method: "post",
				body: data,
			}),
		}),
		searchDetallesVentas: builder.query({
			query: ({ page, ventaNum, name }) =>
				getUrl("searchDetallesVentas") +
				`?page=${page}&ventaNum=${ventaNum}${name || ""}`,
		}),
		deleteDetallesVentas: builder.mutation({
			query: ({ detvCodigo }) => ({
				url: getUrl("deleteDetallesVentas"),
				method: "DELETE",
				body: { detvCodigo },
			}),
		}),
		postGenerateArchVentCart: builder.mutation({
			query: data => ({
				url: getUrl("postGenerateArchVentCart"),
				method: "post",
				body: data,
			}),
		}),
		generateArchXls: builder.mutation({
			query: data => ({
				url: getUrl("generateArchXls"),
				method: "post",
				body: data,
			}),
		}),
		viewEmpresaImg: builder.query({
			query: () => getUrl("viewEmpresaImg"),
		}),
		searchVentaCarteraGen: builder.query({
			query: ({ page, fecha }) =>
				getUrl("searchVentaCarteraGen") + `?page=${page}&fecha=${fecha || ""}`,
		}),
		getCuotasPagoNoPd: builder.query({
			query: ({ ctnuco, ctanex, filter }) =>
				getUrl("getCuotasPagos") +
				`?&CTNUCO=${ctnuco}&CTANEX=${ctanex}&Filtro=${filter}`,
		}),
		getProducts: builder.query({
			query: ({ numdoc, clieCodigo }) =>
				getUrl("getProducts") + `?&NUMDOC=${numdoc}&CLIE_CODIGO=${clieCodigo}`,
		}),
		getCreditData: builder.query({
			query: ({ clieCodigo, ctnuco, ctanex }) =>
				getUrl("getCreditData") +
				`?&clie_codigo=${clieCodigo}&ctnuco=${ctnuco}&ctanex=${ctanex}`,
		}),
		getTickets: builder.query({
			query: ({ ctnuco, ctanex, page }) =>
				getUrl("getTickets") +
				`?&CTNUCO=${ctnuco}&CTANEX=${ctanex}&page=${page}`,
		}),
		getDetailTickets: builder.query({
			query: ({ numeroTiket, page }) =>
				getUrl("getDetailsTickets") +
				`?&numeroTiket=${numeroTiket}&page=${page}`,
		}),
		getDetailTicketsReverse: builder.query({
			query: ({ numeroTiket, page }) =>
				getUrl("getDetailsReverse") +
				`?&numeroTiket=${numeroTiket}&page=${page}`,
		}),
		getDetailTicketsReversePrecancelacion: builder.query({
			query: ({ numeroTiket, page }) =>
				getUrl("getDetailsReversePrecancelacion") +
				`?&numeroTiket=${numeroTiket}&page=${page}`,
		}),
		getUsersInfo: builder.query({
			query: ({ page, param, filtroAgen }) =>
				getUrl("getUsersInfo") +
				`?page=${page}&param=${param}&filtroAgen=${filtroAgen}`,
		}),

		getUsersStatus: builder.query({
			query: () => getUrl("getUsersInfo") + "?catalogs=yes",
		}),
		postUser: builder.mutation({
			query: data => ({
				url: getUrl("getUsersInfo"),
				method: "post",
				body: data,
			}),
		}),
		putUser: builder.mutation({
			query: data => ({
				url: getUrl("getUsersInfo"),
				method: "put",
				body: data,
			}),
		}),
		getProfiles: builder.query({
			query: ({ param, page }) =>
				getUrl("profiles") + "?param=" + param + "&page=" + page,
		}),
		postProfile: builder.mutation({
			query: data => ({
				url: getUrl("profiles"),
				method: "post",
				body: data,
			}),
		}),
		putProfile: builder.mutation({
			query: data => ({
				url: getUrl("profiles"),
				method: "put",
				body: data,
			}),
		}),
		postProfileOption: builder.mutation({
			query: data => ({
				url: getUrl("profilesOptions"),
				method: "post",
				body: data,
			}),
		}),
		postPrecierre: builder.mutation({
			query: data => ({
				url: getUrl("postPrecierre"),
				method: "post",
				body: data,
			}),
		}),
		postCierre: builder.mutation({
			query: data => ({
				url: getUrl("postCierre"),
				method: "post",
				body: data,
			}),
		}),

		getSales: builder.query({
			query: ({ numOper }) => getUrl("getSales") + `?numOper=${numOper}`,
		}),
		getTransactions: builder.query({
			query: ({ numGar, tipoGar, subGar }) =>
				getUrl("getTransactions") +
				`?NUMGAR=${numGar}&TGAR=${tipoGar}&STGA=${subGar}`,
		}),
		getTicketsReverse: builder.query({
			query: ({
				NumCredito,
				IdentiNombre,
				FechaDesde,
				FechaHasta,
				page,
				todosTikets = false,
				usuario = "",
			}) =>
				getUrl("getTicketsReverse") +
				`?&NumCredito=${NumCredito}&IdentiNombre=${IdentiNombre}&FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}&page=${page}&todosTikets=${todosTikets}&usuario=${usuario}`,
		}),

		getTicketsReversePrecancelacion: builder.query({
			query: ({
				NumCredito,
				IdentiNombre,
				FechaDesde,
				FechaHasta,
				page,
				todosTikets = false,
				usuario = "",
			}) =>
				getUrl("getTicketsReversePrecancelacion") +
				`?&NumCredito=${NumCredito}&IdentiNombre=${IdentiNombre}&FechaDesde=${FechaDesde}&FechaHasta=${FechaHasta}&page=${page}&todosTikets=${todosTikets}&usuario=${usuario}`,
		}),
		generateInfoDocs: builder.mutation({
			query: data => ({
				url: getUrl("generateInfoDocs"),
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		createCreditDocuments: builder.mutation({
			query: data => ({
				url: getUrl("createCreditDocument"),
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		createCreditDocumentsEasycell: builder.mutation({
			query: data => ({
				url: getUrl("createCreditDocumentEasycell"),
				method: "POST",
				body: data,
			}),
		}),
		getMyRequest: builder.query({
			query: ({ usuario, page, numcredito, nombrecliente }) =>
				getUrl("requestAuth") +
				`?USUARIO=${usuario}&page=${page}&numcredito=${numcredito}&nombrecliente=${nombrecliente}`,
		}),
		getAllRequest: builder.query({
			query: ({ page, numcredito, nombrecliente }) =>
				getUrl("printAuth") +
				`?page=${page}&numcredito=${numcredito}&nombrecliente=${nombrecliente}`,
		}),
		getCatalogsAuth: builder.query({
			query: ({ agency }) => getUrl("catalogsAuth") + `?AGENCIA=${agency}`,
		}),
		postNewRequest: builder.mutation({
			query: data => ({
				url: getUrl("requestAuth"),
				method: "post",
				body: data,
			}),
		}),
		postManRequest: builder.mutation({
			query: data => ({
				url: getUrl("printAuth"),
				method: "put",
				body: data,
			}),
		}),
		postEditRequest: builder.mutation({
			query: data => ({
				url: getUrl("requestAuth"),
				method: "put",
				body: data,
			}),
		}),
		deleteRequest: builder.mutation({
			query: data => ({
				url: getUrl("requestAuth"),
				method: "delete",
				body: data,
			}),
		}),
		getProductsFinanc: builder.query({
			query: ({ page, productoVar }) =>
				getUrl("getProductsFinanc") +
				`?page=${page}&productoVar=${productoVar}`,
		}),
		updtProductosFinanc: builder.mutation({
			query: data => ({
				url: getUrl("updtProductosFinanc"),
				method: "put",
				body: data,
			}),
		}),
		getPlazosProductosFinanc: builder.query({
			query: ({ page, productoId }) =>
				getUrl("getPlazosProductosFinanc") +
				`?page=${page}&productoId=${productoId}`,
		}),
		updtPlazosProductosFinanc: builder.mutation({
			query: data => ({
				url: getUrl("updtPlazosProductosFinanc"),
				method: "put",
				body: data,
			}),
		}),
		getOtrosProductosFinanc: builder.query({
			query: ({ page, productoId }) =>
				getUrl("getOtrosProductosFinanc") +
				`?page=${page}&productoId=${productoId}`,
		}),
		updtOtrosProductosFinanc: builder.mutation({
			query: data => ({
				url: getUrl("updtOtrosProductosFinanc"),
				method: "put",
				body: data,
			}),
		}),
		getCredits: builder.query({
			query: queryParams => getUrl("creditos") + queryParams ?? "",
		}),
		cancellationCredit: builder.mutation({
			query: ({ pk, data }) => ({
				url: getUrl("creditos") + `/${pk}`,
				method: "DELETE",
				body: data,
			}),
		}),
		getCotizacion: builder.query({
			query: queryParams => getUrl("cotizacion") + queryParams ?? "",
		}),
		getSubCatalogoCartera: builder.query({
			query: queryParams => getUrl("subCatalogCartera") + queryParams ?? "",
		}),
		getTiposPagosProdFinanc: builder.query({
			query: ({ page, productoId }) =>
				getUrl("getTiposPagosProdFinanc") +
				`?page=${page}&productoId=${productoId}`,
		}),
		postTiposPagosProdFinanc: builder.mutation({
			query: data => ({
				url: getUrl("postTiposPagosProdFinanc"),
				method: "post",
				body: data,
			}),
		}),
		deleteTiposPagosProdFinanc: builder.mutation({
			query: data => ({
				url: getUrl("postTiposPagosProdFinanc"),
				method: "delete",
				body: data,
			}),
		}),
		getProdfinanfacturacion: builder.query({
			query: ({ page, productoId, tipoRecibo, grupoProducto }) =>
				getUrl("getProdfinanfacturacion") +
				`?page=${page}&productoId=${productoId}&tipoRecibo=${tipoRecibo}&grupoProducto=${grupoProducto}`,
		}),
		updtProdFinanFacturacion: builder.mutation({
			query: data => ({
				url: getUrl("updtProdFinanFacturacion"),
				method: "put",
				body: data,
			}),
		}),
		getAgenCCUsuarios: builder.query({
			query: ({ codigouser }) =>
				getUrl("getAgenCCUsuarios") + `?codigouser=${codigouser}`,
		}),
		getReimpresionPagos: builder.query({
			query: ({ numeroTiket }) =>
				getUrl("getReimpresionPagos") + `?numeroTiket=${numeroTiket}`,
		}),
		getVentanaMenu: builder.query({
			query: ({ page, nameVar, mosiCodigo }) =>
				getUrl("getVentanaMenu") +
				`?page=${page}&nameVar=${nameVar}&mosiCodigo=${mosiCodigo}`,
		}),
		createVentanaMenu: builder.mutation({
			query: data => ({
				url: getUrl("createVentanaMenu"),
				method: "post",
				body: data,
			}),
		}),
		updtVentanaMenu: builder.mutation({
			query: data => ({
				url: getUrl("updtVentanaMenu"),
				method: "put",
				body: data,
			}),
		}),
		getOpcionesMenu: builder.query({
			query: ({ page, nameVar, mosiCodigo }) =>
				getUrl("getOpcionesMenu") +
				`?page=${page}&nameVar=${nameVar}&mosiCodigo=${mosiCodigo}`,
		}),
		createOpcionMenu: builder.mutation({
			query: data => ({
				url: getUrl("createOpcionMenu"),
				method: "post",
				body: data,
			}),
		}),
		updtOpcionMenu: builder.mutation({
			query: data => ({
				url: getUrl("updtOpcionMenu"),
				method: "put",
				body: data,
			}),
		}),
		getPerfilMenu: builder.query({
			query: ({ page, nameVar, mosiCodigo }) =>
				getUrl("getPerfilMenu") +
				`?page=${page}&nameVar=${nameVar}&mosiCodigo=${mosiCodigo}`,
		}),
		updtPerfilMenu: builder.mutation({
			query: data => ({
				url: getUrl("updtPerfilMenu"),
				method: "put",
				body: data,
			}),
		}),
		createPerfilMenu: builder.mutation({
			query: data => ({
				url: getUrl("createPerfilMenu"),
				method: "post",
				body: data,
			}),
		}),
		getPerfil: builder.query({
			query: ({ page, nameVar }) =>
				getUrl("getPerfil") + `?page=${page}&nameVar=${nameVar}`,
		}),
		createPerfil: builder.mutation({
			query: data => ({
				url: getUrl("createPerfil"),
				method: "post",
				body: data,
			}),
		}),
		updtPerfil: builder.mutation({
			query: data => ({
				url: getUrl("updtPerfil"),
				method: "put",
				body: data,
			}),
		}),
		postReversoPagos: builder.mutation({
			query: data => ({
				url: getUrl("reversoPagos"),
				method: "post",
				body: data,
			}),
		}),
		postReversoPagosPrecancelacion: builder.mutation({
			query: data => ({
				url: getUrl("reversoPagosPrecancelacion"),
				method: "post",
				body: data,
			}),
		}),
		getPerfilMenuOpc: builder.query({
			query: ({ page, nameVar, mosiCodigo }) =>
				getUrl("getPerfilMenuOpc") +
				`?page=${page}&nameVar=${nameVar}&mosiCodigo=${mosiCodigo}`,
		}),
		updtPerfilMenuOpc: builder.mutation({
			query: data => ({
				url: getUrl("updtPerfilMenuOpc"),
				method: "put",
				body: data,
			}),
		}),
		createPerfilMenuOpc: builder.mutation({
			query: data => ({
				url: getUrl("createPerfilMenuOpc"),
				method: "post",
				body: data,
			}),
		}),
		deleteOpcionMenu: builder.mutation({
			query: ({ opmeCodigo, tipeCod }) => ({
				url: getUrl("deleteOpcionMenu"),
				method: "DELETE",
				body: { opmeCodigo, tipeCod },
			}),
		}),
		deletePerfilVentana: builder.mutation({
			query: ({ opmeCodigo, ventCodigo }) => ({
				url: getUrl("deletePerfilVentana"),
				method: "DELETE",
				body: { opmeCodigo, ventCodigo },
			}),
		}),
		updtDetallesVentas: builder.mutation({
			query: data => ({
				url: getUrl("updtDetallesVentas"),
				method: "put",
				body: data,
			}),
		}),
		postPagosGenerateCuota: builder.mutation({
			query: data => ({
				url: getUrl("postPagosGenerateCuota"),
				method: "post",
				body: data,
			}),
		}),
		postValidacionArchivo: builder.mutation({
			query: data => ({
				url: getUrl("postValidacionArchivo"),
				method: "post",
				body: data,
			}),
		}),
		getReportesCartera: builder.query({
			query: ({ page, nameVar, histBand, productoVar, agenciaVar, fechaVar }) =>
				getUrl("getReportesCartera") +
				`?page=${page}&nameVar=${nameVar}&histBand=${histBand}&productoVar=${productoVar}&agenciaVar=${agenciaVar}&fechaVar=${fechaVar}`,
		}),

		postDescargaLogCuotas: builder.mutation({
			query: data => ({
				url: getUrl("getDescargarCuotaRecepcion"),
				method: "post",
				body: data,
			}),
		}),

		postDescargaLog: builder.mutation({
			query: data => ({
				url: getUrl("getDescargarLog"),
				method: "post",
				body: data,
			}),
		}),
		getLogRecepcion: builder.query({
			query: ({ proceso, nombre, convenio }) =>
				getUrl("getLogRecepcion") +
				`?proceso=${proceso}&nombre=${nombre}&convenio=${convenio}`,
		}),

		getRecepcionPichincha: builder.query({
			query: ({ idEnvioRecepcion, page, nombre, credito }) =>
				getUrl("postPagosGenerateCuota") +
				`?idEnvioRecepcion=${idEnvioRecepcion}` +
				`&page=${page}` +
				`&nombre=${nombre}` +
				`&credito=${credito}`,
		}),

		getEmpresaCobranza: builder.query({
			query: (page, nameVar, id) =>
				getUrl("administracionEmpresaCobranza") +
				`?page=${page}&nameVar=${nameVar}&id=${id}`,
		}),
		createEmpresaCobranza: builder.mutation({
			query: data => ({
				url: getUrl("administracionEmpresaCobranza"),
				method: "post",
				body: data,
			}),
		}),
		updateEmpresaCobranza: builder.mutation({
			query: data => ({
				url: getUrl("updateadminEmpresaCobranza"),
				method: "put",
				body: data,
			}),
		}),
		getCargaEmprCobranza: builder.query({
			query: ({ page, nameVar }) =>
				getUrl("cargaemrpcobranza") + `?page=${page}&nameVar=${nameVar}`,
		}),
		postCargaEmprCobranza: builder.mutation({
			query: data => ({
				url: getUrl("postEmprCobranzaXls"),
				method: "post",
				body: data,
			}),
		}),
		deleteCargaEmprCobranza: builder.mutation({
			query: ({ cznuczCodigo }) => ({
				url: getUrl("deleteCargaEmprCobranza"),
				method: "DELETE",
				body: { cznuczCodigo },
			}),
		}),
		getRequestLogs: builder.query({
			query: ({
				page,
				ref,
				httpMethod,
				statusCode,
				dateStart,
				dateEnd,
				requestUrl,
			}) => {
				return (
					getUrl("getRequestLogs") +
					`?page=${page}&ref=${ref || ""}&httpMethod=${
						httpMethod || ""
					}&statusCode=${statusCode || ""}&dateStart=${
						dateStart || ""
					}&dateEnd=${dateEnd || ""}&requestUrl=${requestUrl || ""}`
				);
			},
		}),
		getRequestLogDetails: builder.query({
			query: ({ page, pk, nameVar }) =>
				`${getUrl("getRequestLogs")}/${pk}?page=${page}&nameVar=${nameVar}`,
		}),
		getComportCrediticio: builder.query({
			query: ({
				page,
				cancDesde,
				cancHasta,
				iADesde,
				iAHasta,
				cuotaMin,
				codBuro,
				mismoDia,
				result,
				estado,
			}) =>
				getUrl("comportCrediticio") +
				`?page=${page}&cancDesde=${cancDesde}&cancHasta=${cancHasta}&iADesde=${iADesde}&iAHasta=${iAHasta}&cuotaMin=${cuotaMin}&codBuro=${codBuro}&mismoDia=${mismoDia}&result=${result}&estado=${estado}`,
		}),
		getCatalgComportCrediticio: builder.query({
			query: () => getUrl("catalogcomportcrediticio"),
		}),
		getDetailComportCrediticio: builder.query({
			query: ({ id }) => `${getUrl("comportCrediticio")}/${id}`,
		}),
		updateComportCrediticio: builder.mutation({
			query: data => ({
				url: getUrl("comportCrediticio"),
				method: "put",
				body: data,
			}),
		}),
		createComportCrediticio: builder.mutation({
			query: data => ({
				url: getUrl("comportCrediticio"),
				method: "post",
				body: data,
			}),
		}),
		generateComportCrediticioXls: builder.mutation({
			query: data => ({
				url: getUrl("comportcrediticioxls"),
				method: "post",
				body: data,
			}),
		}),
		getTiketsReportes: builder.query({
			query: ({
				page,
				nameVar,
				histBand,
				productoVar,
				agenciaVar,
				fechaVar,
				tienReferencia,
			}) =>
				getUrl("tiketsReportes") +
				`?page=${page}&nameVar=${nameVar}&histBand=${histBand}&productoVar=${productoVar}&agenciaVar=${agenciaVar}&fechaVar=${fechaVar}&tieneRef=${tienReferencia}`,
		}),
		postXmlTiketsReportes: builder.mutation({
			query: ({
				page,
				nameVar,
				histBand,
				productoVar,
				agenciaVar,
				fechaVar,
				tienReferencia,
			}) => ({
				url:
					getUrl("tiketsReportes") +
					`?page=${page}&nameVar=${nameVar}&histBand=${histBand}&productoVar=${productoVar}&agenciaVar=${agenciaVar}&fechaVar=${fechaVar}&tieneRef=${tienReferencia}`,
				method: "post",
			}),
		}),
		generateUsersXls: builder.mutation({
			query: data => ({
				url: getUrl("usersxls"),
				method: "post",
				body: data,
			}),
		}),
		getPagosSidesoft: builder.query({
			query: ({ tknutk }) => getUrl("pagosSidesoft") + `?tiket=${tknutk}`,
		}),
		getReversosSidesoft: builder.query({
			query: ({ tknutk }) => getUrl("reversosSidesoft") + `?tiket=${tknutk}`,
		}),
		getEmpresaCatalogos: builder.query({
			query: params => getUrl("empresaCatalogos") + params || "",
		}),
		getEmpresaSubCatalogos: builder.query({
			query: params => getUrl("empresaSubCatalogos") + params || "",
		}),
		updateEmpresaCatalogos: builder.mutation({
			query: ({ id, data }) => ({
				url: getUrl("empresaCatalogos") + `/${id}`,
				method: "put",
				body: data,
			}),
		}),
		createEmpresaCatalogos: builder.mutation({
			query: ({ data }) => ({
				url: getUrl("empresaCatalogos"),
				method: "post",
				body: data,
			}),
		}),
		getModulosSistema: builder.query({
			query: params => getUrl("moduloSistema") + params || "",
		}),
		updateSubCatalogos: builder.mutation({
			query: ({ id, data }) => ({
				url: getUrl("empresaSubCatalogos") + `/${id}`,
				method: "put",
				body: data,
			}),
		}),
		createSubCatalogos: builder.mutation({
			query: ({ data }) => ({
				url: getUrl("empresaSubCatalogos"),
				method: "post",
				body: data,
			}),
		}),
		getDatosDinamicosClienteNatural: builder.query({
			query: params => getUrl("datosDinamicosClienteNatural") + params || "",
		}),
		getInfVariable: builder.query({
			query: params => getUrl("inforVariables") + params || "",
		}),
		createInfVariable: builder.mutation({
			query: ({ data }) => ({
				url: getUrl("inforVariables"),
				method: "post",
				body: data,
			}),
		}),
		updateInfVariable: builder.mutation({
			query: ({ id, data }) => ({
				url: getUrl("inforVariables") + `/${id}`,
				method: "put",
				body: data,
			}),
		}),
		updateDesctCobranza: builder.mutation({
			query: ({ ctnuco, ctanex, cnnucn, data }) => ({
				url: getUrl("desctCobranza") + `/${ctnuco}/${ctanex}/${cnnucn}`,
				method: "put",
				body: data,
			}),
		}),
		getDescCobranzas: builder.query({
			query: ({ ctnuco, ctanex, cnnucn, ced }) =>
				getUrl("desctCobranza") +
				`?ctnuco=${ctnuco}&ctanex=${ctanex}&cnnucn=${cnnucn}&ced=${ced}`,
		}),
		descCobranzasXls: builder.mutation({
			query: data => ({
				url: getUrl("desccobranzasxls"),
				method: "post",
				body: data,
			}),
		}),
		getClientObservations: builder.query({
			query: params => getUrl("clientObservations") + params || "",
		}),
		getBotonesDinamicosEmpresa: builder.query({
			query: params => getUrl("botonesDinamicosEmpresa") + params || "",
		}),
		createExcepcionCliente: builder.mutation({
			query: data => ({
				url: getUrl("clientExcepcion"),
				method: "post",
				body: data,
			}),
		}),
		getReportCreditoDescCobrz: builder.query({
			query: ({ page, estado, fechaDesde, fechaHasta }) =>
				getUrl("reportedesccobranzas") +
				`?page=${page}&estado=${estado}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
		}),
		getBotonVariable: builder.query({
			query: params => getUrl("botonesDinamicos") + params || "",
		}),
		createBotonVariable: builder.mutation({
			query: ({ data }) => ({
				url: getUrl("botonesDinamicos"),
				method: "post",
				body: data,
			}),
		}),
		updateBotonVariable: builder.mutation({
			query: ({ id, data }) => ({
				url: getUrl("botonesDinamicos") + `/${id}`,
				method: "put",
				body: data,
			}),
		}),
		createNormalizacion52: builder.mutation({
			query: data => ({
				url: getUrl("normalizacion"),
				method: "POST",
				body: data,
			}),
		}),
		getNormalizacionn: builder.query({
			query: ({ ctnuco, ctanex }) =>
				getUrl("normalizacion") + `?ctnuco=${ctnuco}&ctanex=${ctanex}`,
		}),
		clienteProducts: builder.query({
			query: ({ numdoc, clieCodigo }) =>
				getUrl("clienteProductos") +
				`?&NUMDOC=${numdoc}&CLIE_CODIGO=${clieCodigo}`,
		}),
		updateClienteProducts: builder.mutation({
			query: data => ({
				url: getUrl("clienteProductos"),
				method: "put",
				headers: {
					"Content-Type": "application/json",
				},
				body: data,
			}),
		}),
		getReporteRecaudacion: builder.query({
			query: ({ page, userVar, agenciaVar, fechaDesde, fechaHasta }) =>
				getUrl("reporteRecaudacion") +
				`?page=${page}&userVar=${userVar}&agenciaVar=${agenciaVar}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
		}),
		reporteRecaudacionXls: builder.mutation({
			query: data => ({
				url: getUrl("reporteRecaudacionXls"),
				method: "post",
				body: data,
			}),
		}),
		getCompCrediticioClient: builder.query({
			query: clieIdentificacion =>
				getUrl("getCompCrediticioClient") + `/${clieIdentificacion}`,
		}),
		generateDocsEasy: builder.mutation({
			query: data => ({
				url: getUrl("generateDocsEasy"),
				method: "POST",
				body: data,
			}),
		}),
		sendEamilCartola: builder.mutation({
			query: data => ({
				url: getUrl("sendEamilCartola"),
				method: "POST",
				body: data,
			}),
		}),
		searchClientCredit: builder.query({
			query: numCredit => getUrl("searchClientCredit") + `/${numCredit}`,
		}),
		getEmailTemplate: builder.query({
			query: query => getUrl("emailTemplate") + query,
		}),
		mutationEmailTemplate: builder.mutation({
			query: ({ body, method, query }) => ({
				url: `${getUrl("emailTemplate")}${query || ""}`,
				method,
				body,
			}),
		}),
		generatewArchXls: builder.mutation({
			query: data => ({
				url: getUrl("generateArchWXls"),
				method: "post",
				body: data,
			}),
		}),
		getReportesCarteraWiiCar: builder.query({
			query: ({ page, nameVar, histBand, productoVar, agenciaVar, fechaVar }) =>
				getUrl("getReportesCarteraWiiCar") +
				`?page=${page}&nameVar=${nameVar}&histBand=${histBand}&productoVar=${productoVar}&agenciaVar=${agenciaVar}&fechaVar=${fechaVar}`,
		}),
		getCreditoEventos: builder.query({
			query: query => getUrl("getCreditoEventos") + query,
		}),
		mutationCreditoEventos: builder.mutation({
			query: ({ body, method, query }) => ({
				url: `${getUrl("getCreditoEventos")}${query || ""}`,
				method,
				body,
			}),
		}),
		envioDocsMail: builder.mutation({
			query: data => ({
				url: getUrl("getCreditoEventos"),
				method: "POST",
				body: data,
			}),
		}),
		cancelacionCreditoGen: builder.mutation({
			query: ({ pk, data }) => ({
				url: getUrl("anulacionCreditoGen") + `/${pk}`,
				method: "DELETE",
				body: data,
			}),
		}),
		getCreditosGenDia: builder.query({
			query: queryParams => getUrl("getCreditosGenDia") + queryParams ?? "",
		}),
	}),
});

export const {
	useGetAllCatologsQuery,
	useLazyGetAllCatologsQuery,
	useSearchClientMutation,
	useSearchClientDetailMutation,
	useUpdateClientMutation,
	useUpdateAddressMutation,
	useUpdatePhoneMutation,
	useUpdateBondMutation,
	useUpdateObservationsMutation,
	useGetAdressClientMutation,
	useGetSelectedCatalogsMutation,
	useGetFreeQuotesDetailQuery,
	usePostFreeQuotesMutation,
	useGetAllPhoneMutation,
	useGetReferencesMutation,
	useGetCreeditForDocsQuery,
	useLazyGetCreeditForDocsQuery,
	useGetCatalogCreditReportQuery,
	useGenerateDocumentMutation,
	useGetReportCuotasGratisQuery,
	useLazyGetReportCuotasGratisQuery,
	useLazyGetReportCartolaQuery,
	useGetPortfolioTablesMutation,
	usePostPortfolioTablesMutation,
	useUpdatePortfolioTablesMutation,
	usePostPortfolioItemsMutation,
	useGetQuoteCartolaMutation,
	usePostParametersMutation,
	useGetCuotasArchivoMutation,
	usePostEnviarEmailMutation,
	usePostCashMutation,
	useGetExeptionMutation,
	usePostExeptionMutation,
	useGetDireccionIpMutation,
	usePutExeptionMutation,
	usePutCashMutation,
	useLazyGetCashQuery,
	useGetProcesosCierreMutation,
	useGetModulosCierreMutation,
	usePostProcesosCierreMutation,
	usePutProcesosCierreMutation,
	useGetPaymentMethodsQuery,
	usePostPaymentMethodsMutation,
	useGetReferencesPaymentMethodsQuery,
	usePostReferencesPaymentMethodsMutation,
	usePutReferencesPaymentMethodsMutation,
	useLazyGetQuoteCartolaQuery,
	useGetQuoteCartolaQuery,
	useGetGastoCobranzaMutation,
	useCreateGastoCobranzaMutation,
	useUpdateGastoCobranzaMutation,
	useGetGastoCobranzaDetalleQuery,
	useCreateGastoCobranzaDetalleMutation,
	useUpdateGastoCobranzaDetalleMutation,
	useLazySearchCreditPaymentQuery,
	useSearchCreditPaymentQuery,
	useLazySearchCreditPaymentPrecancelacionQuery,
	usePostPagosMutation,
	usePostPagosPrecancelacionMutation,
	useLazyGetDiscountHeadersQuery,
	useGetDiscountDetailsQuery,
	usePostQuotesDiscountMutation,
	usePostGetDetailDiscountMutation,
	useDeleteDetailDiscountMutation,
	useSearchAgenciaQuery,
	useLazySearchAgenciaQuery,
	useCreateAgenciaMutation,
	useUpdateAgenciaMutation,
	useSearchCentrosQuery,
	useLazySearchCentrosQuery,
	useCreateCentrosMutation,
	useUpdateCentrosMutation,
	useLazySearchEmpresaQuery,
	useSearchEmpresaQuery,
	useLazyGetCuotasPagoQuery,
	useLazyGetCuotasPagoPrecancelacionQuery,
	useUpdateEmpresaMutation,
	useSearchTasasIntQuery,
	useLazySearchTasasIntQuery,
	useLazySearchTasasDetallesQuery,
	useLazySearchTasasDetallesDatosQuery,
	useUpdateTasaMutation,
	useCreateTasaValorMutation,
	useSearchVentaCarteraQuery,
	useLazySearchVentaCarteraQuery,
	useUpdateVentaCarteraMutation,
	useCreateVentaCarteraMutation,
	usePostVentaCartDocsMutation,
	useLazySearchDetallesVentasQuery,
	useDeleteDetallesVentasMutation,
	usePostGenerateArchVentCartMutation,
	useLazyViewEmpresaImgQuery,
	useGenerateArchXlsMutation,
	useLazySearchVentaCarteraGenQuery,
	useLazyGetCuotasPagoNoPdQuery,
	useGetProductsQuery,
	useLazyGetProductsQuery,
	useGetCreditDataQuery,
	useLazyGetCreditDataQuery,
	useGetTicketsQuery,
	useLazyGetTicketsQuery,
	useLazyGetDetailTicketsQuery,
	useLazyGetUsersInfoQuery,
	useLazyGetUsersStatusQuery,
	usePostUserMutation,
	usePutUserMutation,
	useLazyGetProfilesQuery,
	usePostProfileMutation,
	usePutProfileMutation,
	usePostProfileOptionMutation,
	useGetSalesQuery,
	useLazyGetSalesQuery,
	useGetTransactionsQuery,
	useLazyGetTransactionsQuery,
	useLazyGetTicketsReverseQuery,
	useLazyGetTicketsReversePrecancelacionQuery,
	useGenerateInfoDocsMutation,
	usePostPrecierreMutation,
	usePostCierreMutation,
	useCreateCreditDocumentsMutation,
	useLazyGetMyRequestQuery,
	useLazyGetAllRequestQuery,
	useLazyGetCatalogsAuthQuery,
	usePostNewRequestMutation,
	usePostManRequestMutation,
	usePostEditRequestMutation,
	useDeleteRequestMutation,
	useLazyGetProductsFinancQuery,
	useUpdtProductosFinancMutation,
	useLazyGetPlazosProductosFinancQuery,
	useUpdtPlazosProductosFinancMutation,
	useLazyGetOtrosProductosFinancQuery,
	useUpdtOtrosProductosFinancMutation,
	useGetCreditsQuery,
	useLazyGetCreditsQuery,
	useCancellationCreditMutation,
	useGetCotizacionQuery,
	useLazyGetCotizacionQuery,
	useGetSubCatalogoCarteraQuery,
	useLazyGetSubCatalogoCarteraQuery,
	useLazyGetTiposPagosProdFinancQuery,
	usePostTiposPagosProdFinancMutation,
	useDeleteTiposPagosProdFinancMutation,
	useLazyGetProdfinanfacturacionQuery,
	useUpdtProdFinanFacturacionMutation,
	useLazyGetAgenCCUsuariosQuery,
	useLazyGetReimpresionPagosQuery,
	useLazyGetVentanaMenuQuery,
	useCreateVentanaMenuMutation,
	useUpdtVentanaMenuMutation,
	useLazyGetOpcionesMenuQuery,
	useCreateOpcionMenuMutation,
	useUpdtOpcionMenuMutation,
	useLazyGetPerfilMenuQuery,
	useUpdtPerfilMenuMutation,
	useCreatePerfilMenuMutation,
	useLazyGetPerfilQuery,
	useCreatePerfilMutation,
	useUpdtPerfilMutation,
	usePostReversoPagosMutation,
	usePostReversoPagosPrecancelacionMutation,
	useLazyGetPerfilMenuOpcQuery,
	useUpdtPerfilMenuOpcMutation,
	useCreatePerfilMenuOpcMutation,
	useDeleteOpcionMenuMutation,
	useDeletePerfilVentanaMutation,
	useUpdtDetallesVentasMutation,
	usePostPagosGenerateCuotaMutation,
	usePostValidacionArchivoMutation,
	useLazyGetReportesCarteraQuery,
	useLazyGetEmpresaCobranzaQuery,
	useCreateEmpresaCobranzaMutation,
	useUpdateEmpresaCobranzaMutation,
	useLazyGetLogRecepcionQuery,
	useLazyGetRecepcionPichinchaQuery,
	usePostDescargaLogCuotasMutation,
	usePostDescargaLogMutation,
	useLazyGetDetailTicketsReverseQuery,
	useLazyGetDetailTicketsReversePrecancelacionQuery,
	usePostCargaEmprCobranzaMutation,
	useLazyGetCargaEmprCobranzaQuery,
	useDeleteCargaEmprCobranzaMutation,
	useLazyGetRequestLogsQuery,
	useLazyGetRequestLogDetailsQuery,
	useLazyGetComportCrediticioQuery,
	useLazyGetDetailComportCrediticioQuery,
	useUpdateComportCrediticioMutation,
	useCreateComportCrediticioMutation,
	useLazyGetCatalgComportCrediticioQuery,
	useGetCatalgComportCrediticioQuery,
	useGenerateComportCrediticioXlsMutation,
	useLazyGetTiketsReportesQuery,
	usePostXmlTiketsReportesMutation,
	useGenerateUsersXlsMutation,
	useLazyGetPagosSidesoftQuery,
	useLazyGetReversosSidesoftQuery,
	useGetEmpresaCatalogosQuery,
	useLazyGetEmpresaCatalogosQuery,
	useGetEmpresaSubCatalogosQuery,
	useLazyGetEmpresaSubCatalogosQuery,
	useUpdateEmpresaCatalogosMutation,
	useCreateEmpresaCatalogosMutation,
	useGetModulosSistemaQuery,
	useUpdateSubCatalogosMutation,
	useCreateSubCatalogosMutation,
	useGetDatosDinamicosClienteNaturalQuery,
	useLazyGetDatosDinamicosClienteNaturalQuery,
	useGetInfVariableQuery,
	useLazyGetInfVariableQuery,
	useCreateInfVariableMutation,
	useUpdateInfVariableMutation,
	useUpdateDesctCobranzaMutation,
	useLazyGetDescCobranzasQuery,
	useDescCobranzasXlsMutation,
	useLazyGetClientObservationsQuery,
	useGetClientObservationsQuery,
	useGetBotonesDinamicosEmpresaQuery,
	useLazyGetBotonesDinamicosEmpresaQuery,
	useGetClienteQuery,
	useLazyGetClienteQuery,
	useCreateExcepcionClienteMutation,
	useLazyGetReportCreditoDescCobrzQuery,
	useGetBotonVariableQuery,
	useLazyGetBotonVariableQuery,
	useCreateBotonVariableMutation,
	useUpdateBotonVariableMutation,
	useCreateNormalizacion52Mutation,
	useLazyGetNormalizacionnQuery,
	useClienteProductsQuery,
	useUpdateClienteProductsMutation,
	useGetBalancesQuery,
	useLazyGetBalancesQuery,
	useLazyGetReporteRecaudacionQuery,
	useReporteRecaudacionXlsMutation,
	useGetCompCrediticioClientQuery,
	useLazyGetCompCrediticioClientQuery,
	useGenerateDocsEasyMutation,
	useCreateCreditDocumentsEasycellMutation,
	useSendEamilCartolaMutation,
	useSearchClientCreditQuery,
	useLazySearchClientCreditQuery,
	useGetEmailTemplateQuery,
	useLazyGetEmailTemplateQuery,
	useMutationEmailTemplateMutation,
	useGeneratewArchXlsMutation,
	useLazyGetReportesCarteraWiiCarQuery,
	useLazyGetCreditoEventosQuery,
	useMutationCreditoEventosMutation,
	useEnvioDocsMailMutation,
	useCancelacionCreditoGenMutation,
	useLazyGetCreditosGenDiaQuery,
} = postsApi;
