import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	jwt: null,
	user_profile: null,
	menu_access: null,
	activityTime: Date.now(),
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setLoginRequest: (state, action) => {
			const { user, password } = action.payload;
			const userCode = window.btoa(user);
			const passwordCode = window.btoa(password);
			state.requestLoginUser = { user: userCode, password: passwordCode };
		},
		setCredentials: (state, action) => {
			localStorage.setItem("lastActive", Date.now().toString());
			// eslint-disable-next-line camelcase
			const { user_profile, jwt, menu_access } = action.payload;
			state.jwt = jwt;
			// eslint-disable-next-line camelcase
			state.user_profile = user_profile;
			// eslint-disable-next-line camelcase
			state.menu_access = menu_access;
			state.activityTime = Date.now();
		},
		logOut: (state, action) => {
			state.jwt = null;
			state.user_profile = null;
			state.menu_access = null;
		},
		setActivityTime: (state, action) => {
			state.activityTime = action.payload;
		},
	},
	// extraReducers: (builder) => { }
});

export const { setLoginRequest, setCredentials, logOut, setActivityTime } =
	authSlice.actions;

export default authSlice.reducer;

export const useSelectorCurrentUser = state => state.auth.user_profile;
export const useSelectorCurrentToken = state => state.auth.jws;
export const useSelectorCurrentMenuAccess = state => state.auth.menu_access;
export const useSelectorCurrentRequestLoginUser = state =>
	state.auth.requestLoginUser;
