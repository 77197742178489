import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ header: {}, detail: {} }];

export const gastoCobranzaSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    addHeader: (state, action) => {
      state[0].header = action.payload;
    },
    addDetails: (state, action) => {
      state[0].detail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addDetails, addHeader } = gastoCobranzaSlice.actions;

export default gastoCobranzaSlice.reducer;
