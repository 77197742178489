import { createSlice } from "@reduxjs/toolkit";

const initialState = [
	{
		ventas: {},
		datos: {},
		infoVC: {},
		fechaModEmpr: {},
		motivoAnu: {},
		tipoFact: {},
		secValor: {},
	},
];

export const ventaCarteraSlice = createSlice({
	name: "ventaCartera",
	initialState,
	reducers: {
		addVentas: (state, action) => {
			state[0].ventas = action.payload;
		},
		addDatos: (state, action) => {
			state[0].datos = action.payload;
		},
		addInfoVC: (state, action) => {
			state[0].infoVC = action.payload;
		},
		addFechas: (state, action) => {
			state[0].fechaModEmpr = action.payload;
		},
		addMotivos: (state, action) => {
			state[0].motivoAnu = action.payload;
		},
		addtipoFact: (state, action) => {
			state[0].tipoFact = action.payload;
		},
		addSecValor: (state, action) => {
			state[0].secValor = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addVentas,
	addDatos,
	addInfoVC,
	addFechas,
	addMotivos,
	addtipoFact,
	addSecValor,
} = ventaCarteraSlice.actions;

export default ventaCarteraSlice.reducer;
