import PropTypes from "prop-types";
import {
	Navigate,
	Outlet,
	useLocation,
	useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../features/auth/authSlice";
import toastifyAlert from "../../components/ToastifyAlert";
import { checkMatchMenu, isExpired } from "../../utilities/utilities";
import { whiteListUrl } from "../../config/config";

const ProtectedRoute = ({ children }) => {
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.jwt);
	const menu = useSelector(state => state.auth.menu_access);
	const location = useLocation();

	// OBTENER EL SUPER TOKEN DE LA URL
	const [searchParams] = useSearchParams();
	const superToken = searchParams.get("superToken") ?? null;

	// verificar si existe el parametro superToken
	if (superToken) {
		// se borra los datos de session y redirecciona al login
		dispatch(logOut());
		return (
			<Navigate
				exact
				to={`/login?superToken=${superToken}`}
				state={{ location }}
			/>
		);
	}

	// verifica si existe un token
	if (!token) {
		return <Navigate exact to={"/login"} state={{ location }} />;
	} else if (token && isExpired(token)) {
		// verifica si se ha expirado el token
		toastifyAlert("Sesión terminada.", "info");

		dispatch(logOut());
		return <Navigate exact to={"/login"} state={{ location }} />;
	}

	// verifica si el usuario tiene acceso a la ruta
	if (location?.pathname !== "/") {
		const isAllowedMenu = checkMatchMenu(
			menu,
			location?.pathname,
			whiteListUrl,
		);
		if (!isAllowedMenu) {
			return <Navigate exact to={"/"} />;
		}
	}

	return children || <Outlet />;
};

ProtectedRoute.propTypes = {
	children: PropTypes.node,
};

export default ProtectedRoute;
