import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut } from "../features/auth/authSlice";
import { API, apiRoutes } from "../config/apiRoutes";

const baseQuery = fetchBaseQuery({
	baseUrl: API,
	prepareHeaders: (headers, { getState }) => {
		// By default, if we have a token in the store, let's use that for authenticated requests
		const token = getState().auth.jwt;

		headers.set("Content-Type", "application/json");
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		return headers;
	},
});

export const apiCallValidateToken = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);
	if (result?.status === 403) {
		// console.log("token invalid then logout");
		api.dispatch(logOut());
	}
};

export const apiauth = createApi({
	reducerPath: "authApi",
	baseQuery,
	endpoints: builder => ({
		login: builder.mutation({
			query: data => ({
				url: apiRoutes.login,
				method: "POST",
				body: data,
			}),
		}),
		protected: builder.mutation({
			query: () => "protected",
		}),
		validateToken: builder.mutation({
			query: () => ({
				url: apiRoutes.validateToken,
				method: "POST",
				body: {},
			}),
		}),
		superLogin: builder.mutation({
			query: data => ({
				url: apiRoutes.superLogin,
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useProtectedMutation,
	useValidateTokenMutation,
	useSuperLoginMutation,
} = apiauth;
