import { Fragment } from "react";
import { Link } from "react-router-dom";

const HeaderTitle = () => {
	return (
		<Fragment>
			<Link to={"/"} className="fw-bold text-primary h4">
				Sistema de Administración de Cartera
			</Link>
		</Fragment>
	);
};

export default HeaderTitle;
