import { useState, useEffect, useCallback } from "react";

/**
 * The `useIdleLogout` custom hook in JavaScript React helps manage user idle timeout and logout
 * functionality.
 * @returns The `useIdleLogout` custom hook is returning an object with two properties: `isIdle` and
 * `resetTimeout`. The `isIdle` property is a boolean value indicating whether the user is idle or not,
 * and the `resetTimeout` property is a function that can be called to reset the idle timeout.
 */
export const useIdleLogout = (timeout = 60000) => {
	const [isIdle, setIsIdle] = useState(false);

	// reinicia el contador de inactividad y actualiza el localStorage con la última actividad.
	const resetTimeout = useCallback(() => {
		localStorage.setItem("lastActive", Date.now().toString());
		setIsIdle(false);
	}, []);

	// establece una marca en localStorage para que todas las pestañas se sincronicen y ejecuten el cierre de sesión.
	const logout = useCallback(() => {
		localStorage.setItem("logout", Date.now().toString());
		setIsIdle(true);
	}, []);

	useEffect(() => {
		const events = ["mousemove", "keydown", "scroll", "click"];
		const eventHandler = () => resetTimeout();

		events.forEach(event => window.addEventListener(event, eventHandler));

		// verifica periódicamente si ha pasado el tiempo de inactividad y ejecuta logout si es necesario.
		const checkTimeout = () => {
			const lastActive = parseInt(localStorage.getItem("lastActive"), 10);
			if (Date.now() - lastActive >= timeout) {
				logout();
			}
		};

		const interval = setInterval(checkTimeout, 1000);

		return () => {
			events.forEach(event => window.removeEventListener(event, eventHandler));
			clearInterval(interval);
		};
	}, [resetTimeout, logout, timeout]);

	useEffect(() => {
		// escucha el evento storage para sincronizar el cierre de sesión entre pestañas.
		const syncLogout = event => {
			if (event.key === "logout") {
				window.location.reload();
			}
		};

		window.addEventListener("storage", syncLogout);

		return () => {
			window.removeEventListener("storage", syncLogout);
		};
	}, []);

	return { isIdle, resetTimeout };
};
