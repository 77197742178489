import { Fragment } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
	DropdownToggle,
	DropdownMenu,
	Nav,
	Button,
	NavItem,
	NavLink,
	UncontrolledButtonDropdown,
} from "reactstrap";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-toastify/dist/ReactToastify.css";

import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../assets/utils/images/avatars/pngwing.png";

import { logOut } from "../../../features/auth/authSlice";

const UserBox = ({ userProfile }) => {
	const dispatch = useDispatch();
	const handleLogout = () => {
		dispatch(logOut());
	};

	return (
		<Fragment>
			<div className="header-btn-lg pe-0">
				<div className="widget-content p-0">
					<div className="widget-content-wrapper">
						<div className="widget-content-left">
							<div className="widget-heading">
								{userProfile?.empr_nombre ?? "n/a"} /{" "}
								{userProfile?.cetc_descripcion ?? "n/a"}
							</div>
						</div>

						<div className="widget-content-left">
							<UncontrolledButtonDropdown>
								<DropdownToggle color="link" className="p-0">
									<img
										width={42}
										className="rounded-circle"
										src={avatar1}
										alt=""
									/>
									<FontAwesomeIcon
										className="ms-2 opacity-8"
										icon={faAngleDown}
									/>
								</DropdownToggle>
								<DropdownMenu end className="rm-pointers dropdown-menu-lg">
									<div className="dropdown-menu-header">
										<div className="dropdown-menu-header-inner bg-info">
											<div
												className="menu-header-image opacity-2"
												style={{
													backgroundImage: "url(" + city3 + ")",
												}}
											/>
											<div className="menu-header-content text-start">
												<div className="widget-content p-0">
													<div className="widget-content-wrapper">
														<div className="widget-content-left me-3">
															<img
																width={42}
																className="rounded-circle"
																src={avatar1}
																alt=""
															/>
														</div>
														<div className="widget-content-left">
															<div className="widget-heading">
																{userProfile?.usua_nombre ?? "n/a"}
															</div>
															<div className="widget-subheading opacity-8">
																{userProfile?.tipe_descripcion ?? "n/a"}
															</div>
														</div>
														<div className="widget-content-right me-2">
															<Button
																className="btn-pill btn-shadow btn-shine"
																color="focus"
																onClick={handleLogout}
															>
																Cerrar Sesión
															</Button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className="scroll-area-xs"
										style={{
											height: "150px",
										}}
									>
										<PerfectScrollbar>
											<Nav vertical>
												<NavItem>
													<NavLink>
														Empresa
														<div className="ms-auto badge rounded-pill bg-info">
															{userProfile?.empr_nombre ?? "n/a"}
														</div>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink>
														Zona
														<div className="ms-auto badge rounded-pill bg-info">
															{userProfile?.zona_descripcion ?? "n/a"}
														</div>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink>
														Agencia
														<div className="ms-auto badge rounded-pill bg-info">
															{userProfile?.agen_descripcion ?? "n/a"}
														</div>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink href="#">Recuperar Contraseña</NavLink>
												</NavItem>
											</Nav>
										</PerfectScrollbar>
									</div>
									<Nav vertical>
										<NavItem className="nav-item-divider mb-0" />
									</Nav>
								</DropdownMenu>
							</UncontrolledButtonDropdown>
						</div>
						<div className="widget-content-left  ms-3 header-user-info">
							<div className="widget-heading">
								{userProfile?.usua_nombre ?? "n/a"}
							</div>
							<div className="widget-subheading">
								{userProfile?.tipe_descripcion ?? "n/a"}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

UserBox.propTypes = {
	userProfile: PropTypes.object,
};

export default UserBox;
